import React from "react";

const TermsAndConditionsPage = () => {
  return (
    <div className="min-h-screen bg-white flex flex-col items-center">
      <div className="w-full max-w-4xl px-4 py-8 sm:px-6 md:px-8">
        <div className="max-w-2xl mx-auto">
          <div className="text-center mb-8 sm:mb-12">
            <h1 className="text-2xl sm:text-3xl font-bold text-black mb-4">
              Terms and Conditions
            </h1>
            <p className="text-sm sm:text-base text-gray-600 font-normal">
              Please review our terms and conditions carefully before using our website or services.
            </p>
          </div>

          <div className="bg-[#F9F9F9] border border-[#E8E8E8] rounded-md p-4 sm:p-6 mb-4 hover:border-[#495e26] transition-colors duration-200">
            <div className="space-y-4">
              <p className="text-sm sm:text-base text-[#495e26] leading-relaxed">
                By using Luckshack, players acknowledge that they are participating in an online sweepstakes
                and gaming platform, and all play is at their own risk. Luckshack provides a variety of games
                and sweepstakes for entertainment purposes only, and players are responsible for ensuring
                they meet the legal age and eligibility requirements in their jurisdiction.
              </p>
              
              <p className="text-sm sm:text-base text-[#495e26] leading-relaxed">
                It is important to note that Luckshack does not guarantee any specific outcomes or results from participating
                in our games. All games and services are provided "as is," and players should play responsibly.
              </p>
              
              <p className="text-sm sm:text-base text-[#495e26] leading-relaxed">
                Luckshack reserves the right to suspend or ban any account that violates our terms of
                service, engages in unethical behavior, or attempts to manipulate game outcomes.
                By participating, players agree to these terms and conditions in full.
              </p>
            </div>
          </div>
        </div>

        <footer className="mt-16 sm:mt-24 text-center text-xs sm:text-sm text-black">
          Copyright 2024, luckshack All Rights Reserved.
        </footer>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;