import React, { useState, useEffect } from "react";
import axios from "axios";
import { startTransition } from "react";
import { useNavigate } from "react-router-dom";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiEye, FiEyeOff } from "react-icons/fi";
import {toast } from 'react-toastify';

const Signup: React.FC = () => {
  const navigate = useNavigate();

  const [formError, setFormError] = useState<any>({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    // system: "",
    phone: "",
    terms: false,
  });
  const systems = [
    "Golden Dragon",
    "River Sweeps",
    "Orion Stars",
    "Fire Kirin",
    "Panda Master",
    "Magic City",
    "Touch O Luck",
    "Tai Chi",
    "Golden Kirin",
    "Mega Spin",
  ];
  const handleChange = (e: any) => {
    const { name, value } = e.target;

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
 

    if (formError[name]) {
      setFormError((prevErrors: any) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };
  const validateForm = () => {
    const newErrors: any = {};
    if (!formData.firstName) newErrors.firstName = "firstName is required";
    if (!formData.lastName) newErrors.lastName = "lastName is required";
    if (!formData.phone) newErrors.phone = "phone is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.password) newErrors.password = "Password is required";
    // if (!formData.system) newErrors.system = "System is required";

    if (!formData.confirmPassword)
      newErrors.confirmPassword = "Confirm Password is required";
    if (formData.password !== formData.confirmPassword)
      newErrors.confirmPassword = "Passwords do not match";
    if (!formData.terms) newErrors.terms = "You must agree to the Terms of Use";

    setFormError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const createUser = async (e: any) => {
    console.log("IncreateUser---");
    e.preventDefault();
    if (!validateForm()) {
      console.log("Form validation failed");
      return;
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/user/add",
        {
          ...formData,
        }
      );
      console.log("Response from signup form--->>", response.data.data);
      const userId = response.data?.data?._id;

      if (userId) {
        // Save the user ID in localStorage
        localStorage.setItem("userId", userId);
        console.log("User ID saved to local storage:", userId);
      }

      toast.success('You have successfully created new user!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });  
          navigate("/payment");
    } catch (error: any) {
      console.error("Error placing order:", error);
      if (error.response && error.response.status === 409) {
        setFormError((prevErrors: any) => ({
          ...prevErrors,
          email: "User already exists", // For example, associating error with email field
        }));
      } else {
        setFormError((prevErrors: any) => ({
          ...prevErrors,
          general: "An unexpected error occurred. Please try again.",
        }));
      }
    }
  };

  return (
    <div className="">
      <div className="">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Signup</h2>
          <a
            href="/login-page"
            className="text-sm text-gray-600 hover:underline"
          >
            Log in instead
          </a>
        </div>
        <form className="space-y-4" onSubmit={createUser}>
          <div>
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-lime-900 mb-1"
            >
              First name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder="Enter your firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full px-3 py-2 border-1 border-lime-900 rounded-md focus:outline-none focus:ring-1 focus:ring-lime-500"
            />
            {formError.firstName && (
              <p className="text-red-500 text-sm mt-1">{formError.firstName}</p>
            )}
          </div>
          <div>
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-lime-900 mb-1"
            >
              Last name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder="Enter your lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full px-3 py-2 border-1 border-lime-900 rounded-md focus:outline-none focus:ring-1 focus:ring-lime-500"
            />
            {formError.lastName && (
              <p className="text-red-500 text-sm mt-1">{formError.lastName}</p>
            )}
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-lime-900 mb-1"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border-1 border-lime-900 rounded-md focus:outline-none focus:ring-1 focus:ring-lime-500"
            />
            {formError.email && (
              <p className="text-red-500 text-sm mt-1">{formError.email}</p>
            )}
          </div>
          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-lime-900 mb-1"
            >
              Phone
            </label>
            <div className="flex">
              <span className="inline-flex items-center px-3 py-2 border border-r-0 border-lime-900 rounded-l-md bg-gray-100 text-gray-600">
                +1
              </span>
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Enter your phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-lime-900 rounded-r-md focus:outline-none focus:ring-1 focus:ring-lime-500"
              />
            </div>
            {formError.phone && (
              <p className="text-red-500 text-sm mt-1">{formError.phone}</p>
            )}
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-lime-900 mb-1"
            >
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Create a password"
                value={formData.password}
                onChange={handleChange}
                className="w-full px-3 py-2 border-1 border-lime-900 rounded-md focus:outline-none focus:ring-1 focus:ring-lime-500"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
              >
                {showPassword ? <FiEyeOff size={20} /> : <FiEye size={20} />}
              </button>
            </div>
            {formError.password && (
              <p className="text-red-500 text-sm mt-1">{formError.password}</p>
            )}
          </div>
          <div>
            <label
              htmlFor="confirm-password"
              className="block text-sm font-medium text-lime-900 mb-1"
            >
              Confirm Password
            </label>
            <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirm-password"
                name="confirmPassword"
                placeholder="Confirm your password"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="w-full px-3 py-2 border-1 border-lime-900 rounded-md focus:outline-none focus:ring-1 focus:ring-lime-500"
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
              >
                {showConfirmPassword ? (
                  <FiEyeOff size={20} />
                ) : (
                  <FiEye size={20} />
                )}
              </button>
            </div>
            {formError.confirmPassword && (
              <p className="text-red-500 text-sm mt-1">
                {formError.confirmPassword}
              </p>
            )}
          </div>

          <div className="flex items-start">
            <input
              type="checkbox"
              id="terms"
              name="terms"
              checked={formData.terms}
              onChange={handleChange}
              className="mt-1 h-4 w-4 text-olive-600 focus:ring-olive-500 border-gray-300 rounded"
            />
            <label htmlFor="terms" className="ml-2 text-xs text-gray-600">
              By signing up, I agree to the Terms of Use, Privacy Policy, and
              consent to receive emails and updates.{" "}
            </label>
          </div>
          {formError.terms && (
            <p className="text-red-500 text-sm mt-1">{formError.terms}</p>
          )}
          {formError.general && (
            <p className="text-red-500 text-sm mt-1">{formError.general}</p>
          )}
          <button
            style={{ background: "#556B2F", borderRadius: "20px" }}
            type="submit"
            className="w-full py-2 px-4 hover:bg-olive-700 text-white font-semibold rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
          >
            Create Account
          </button>
        </form>
      </div>
    </div>
  );
};

const SignupPage: React.FC = () => {
  const [quantity, setQuantity] = useState<string>("10");
  const [couponCode, setCouponCode] = useState("");
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [productImg, setProductImg] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false); // Static value for login status

  const fetchProductImage = async () => {
    const ProductId = localStorage.getItem("selectedProductId");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/${ProductId}`
      );
      setProductImg(response.data.data);
    } catch (error: any) {
      console.error("Error fetching order :", error);
    }
  };

  useEffect(() => {
    fetchProductImage();
  }, []);

  const navigate = useNavigate();

  const openContinue = () => {
    startTransition(() => {
      navigate("/user-verify");
    });
  };

  const openBackward = () => {
    startTransition(() => {
      navigate("/product-details");
    });
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 font-sans py-8">
      <div className="flex flex-col md:flex-row gap-8 w-full">
        {/* Product Image Section */}
        <div className="md:w-1/2 flex items-start relative">
          <button
            onClick={openBackward}
            className="absolute top-0 left-0 m-2 text-gray-900 z-10"
          >
            <FiArrowLeftCircle size={24} />
          </button>
          {productImg && productImg.imageName ? (
            <img
              src={`https://s3.wasabisys.com/productimage/${productImg.imageName}`}
              alt={productImg.name || "Product Image"}
              className="w-full h-auto max-w-md mx-auto rounded-lg object-cover"
              style={{ backgroundColor: "#f0d889" }}
            />
          ) : (
            <span className="no-image text-center w-full text-gray-500">
              No Image
            </span>
          )}
        </div>

        {/* Login Form Section */}
        <div className="md:w-1/2 w-full mt-8 md:mt-0 flex justify-center">
          {!isUserLoggedIn && (
            <div className="w-full max-w-lg mx-auto p-4 ">
              <Signup />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
