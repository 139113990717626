import React, { useState } from "react";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";

const FAQItem = ({ question, answer }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border border-[#E8E8E8] rounded-md overflow-hidden transition-all duration-200 hover:border-[#495e26]">
      <button
        className="flex w-full items-center justify-between text-left bg-white px-4 sm:px-6 py-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-sm sm:text-base text-[#495e26] font-medium pr-4">
          {question}
        </span>
        {isOpen ? (
          <FaCaretUp className="h-4 w-4 sm:h-5 sm:w-5 text-[#495e26] flex-shrink-0" />
        ) : (
          <FaCaretDown className="h-4 w-4 sm:h-5 sm:w-5 text-[#495e26] flex-shrink-0" />
        )}
      </button>
      {isOpen && (
        <div className="px-4 sm:px-6 py-4 bg-white border-t border-[#E8E8E8]">
          <p className="text-sm sm:text-base text-[#495e26]">{answer}</p>
        </div>
      )}
    </div>
  );
};

const FAQPage = () => {
  const faqData = [
    {
      question: "How can I get in touch with support after I bought the product?",
      answer: "To get in touch with support after making a purchase, you can use the built-in ticketing system. Simply visit the contact page to submit your details and we will get back to you ASAP.",
    },
    {
      question: "Can I make payments using my preferred method?",
      answer: "Yes, we support a wide range of payment methods, including popular fiat options like Credit Cards as well as various cryptocurrencies. This enables you to make payments using the method that is most convenient for you.",
    },
    {
      question: "Is it safe to make payments?",
      answer: "Yes, we take security very seriously. We use advanced fraud prevention measures to protect against fraudulent transactions and we securely store all payment information.",
    },
    {
      question: "How do I make a purchase?",
      answer: "Simply add items to your cart, proceed to checkout, enter your shipping and payment information, and confirm your order.",
    },
    {
      question: "What is the return policy for purchases?",
      answer: "We offer a 30-day return policy for most items. Please check our returns page for detailed information and instructions.",
    },
  ];

  return (
    <div className="min-h-screen bg-white flex flex-col items-center">
      <div className="w-full max-w-4xl px-4 py-8 sm:px-6 md:px-8">
        <div className="max-w-2xl mx-auto">
          <div className="text-center mb-8 sm:mb-12">
            <h1 className="text-2xl sm:text-3xl font-bold text-black mb-4">
              FAQ
            </h1>
            <p className="text-sm sm:text-base text-gray-600 font-normal">
              Get a question or need some help? Get in touch, we'd love to hear from you.
            </p>
          </div>

          <div className="space-y-4">
            {faqData.map((item, index) => (
              <FAQItem
                key={index}
                question={item.question}
                answer={item.answer}
              />
            ))}
          </div>
        </div>
        
        <footer className="mt-16 sm:mt-24 text-center text-xs sm:text-sm text-black">
          Copyright 2024, luckshack All Rights Reserved.
        </footer>
      </div>
    </div>
  );
};

export default FAQPage;