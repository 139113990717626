
import React, { useState, FormEvent, ChangeEvent } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../contexts/userContext";
import LoginSvg from "../../../icons/login.svg";
import { FiEye, FiEyeOff } from "react-icons/fi";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [formError, setFormError] = useState<any>({});
  const [email, setEmail] = useState<string>("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);

    if (formError.email) {
      setFormError((prevErrors: any) => ({
        ...prevErrors,
        email: "",
      }));
    }
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    if (!email) {
      setFormError({ email: "Email is required" });
      return;
    }

    try {
      // Send reset password link to the email
      await axios.post( process.env.REACT_APP_API_URL +'/send-reset-password-link', { email });
      // Navigate to confirmation page
      navigate("/reset-password");
    } catch (error) {
      setFormError({ email: "Failed to send reset password email" });
    }
  };
  return (
    <div className="flex justify-center items-center min-h-screen p-4">
      <div className="flex flex-col md:flex-row bg-white rounded-lg overflow-hidden w-full max-w-4xl items-center">
     
  
        <div className="p-8 w-full md:w-1/2 flex flex-col items-center">
          <h2 className="text-2xl font-semibold text-gray-800 mb-6">
            Reset your password
          </h2>
          <form className="w-full max-w-sm space-y-4" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-600 mb-1"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
                className="w-full px-3 py-2 border-1 border-lime-900 rounded-md focus:outline-none focus:ring-1 focus:ring-lime-500"
              />
              {formError.email && (
                <p className="text-red-500 text-sm mt-1">{formError.email}</p>
              )}
            </div>
            <button
              style={{ background: "#556B2F", borderRadius: "20px" }}
              type="submit"
              className="w-full py-2 px-4 hover:bg-olive-700 text-white font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              Continue
            </button>
          </form>
        </div>
   <div className="w-full md:w-1/2 bg-olive-100 flex items-center justify-center p-8">
          <img
            src={LoginSvg}
            alt="Signup illustration"
            className="w-full max-w-md h-auto"
          />
        </div>
      </div>
    </div>
  );
  
/*
return (
    <div className="flex justify-center items-center min-h-screen p-4">
      <div className="flex flex-col md:flex-row bg-white rounded-lg overflow-hidden w-full max-w-4xl">
        <div className="p-8 w-full md:w-1/2">
          <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2 sm:mb-0">
              Reset your password
            </h2>
          </div>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-600 mb-1"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
                className="w-full px-3 py-2 border-1 border-lime-900 rounded-md focus:outline-none focus:ring-1 focus:ring-lime-500"
              />
              {formError.email && (
                <p className="text-red-500 text-sm mt-1">{formError.email}</p>
              )}
            </div>
          
            <button
              style={{ background: "#556B2F", borderRadius: "20px" }}
              type="submit"
              className="w-full py-2 px-4 hover:bg-olive-700 text-white font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 "
            >
             Continue
            </button>
          </form>
        </div>
        <div className="w-full md:w-1/2 bg-olive-100 flex items-center justify-center p-8">
          <img
            src={LoginSvg}
            alt="Signup illustration"
            className="w-full max-w-md h-auto"
          />
        </div>
      </div>
    </div>
  );   */
};

export default ForgotPassword;
