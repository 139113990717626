
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowCircleRight } from "react-icons/md";

type Product = {
  _id: string;
  name: string;
  price: string;
  status: boolean;
  imageName: string;
  system: string;
};

const ProductItem: React.FC<Product> = ({ name, _id, imageName }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleBuyNowClick = (productId: string) => {
    localStorage.setItem("selectedProductId", productId);
    console.log("productId in Buy now---", productId);
    navigate(`/product-details?productId=${productId}`);
  };

  return (
    <div
      className="border shadow-md hover:shadow-lg transition duration-300 relative overflow-hidden w-64 h-64"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        clipPath: "inset(5%)",
      }}
    >
      <div className="w-full h-full">
        {imageName ? (
          <img
            src={`https://s3.wasabisys.com/productimage/${imageName}`}
            alt={name}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-gray-200">
            <span className="text-gray-600">No image available</span>
          </div>
        )}
      </div>
      <div className="absolute bottom-0 left-0 right-0 mx-2 mb-2">
        <div
          className={`transition-all duration-300 ${
            isHovered ? "h-16" : "h-0"
          } overflow-hidden mx-2`}
        >
          <button
            onClick={() => handleBuyNowClick(_id)}
            className="w-full py-2 bg-gray-900 text-white"
          >
            Buy Now
          </button>
        </div>
        <div className="bg-black text-white">
          <h3 className="text-lg px-2">E Token</h3>
          <h1 className="text-sm px-2 text-lime-900 pb-2">Choose Your Token</h1>
        </div>
      </div>
    </div>
  );
};

const ProductsSection: React.FC = () => {
  const [productList, setProductList] = useState<Product[]>([]);

  const fetchProduct = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      setProductList(response.data.data);
    } catch (error: any) {
      console.error("Error fetching product:", error);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  return (
    <section className="mb-12 w-full px-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl">Our Products</h2>
        {/* <a
          href="/shop"
          className="text-gray-900 hover:text-gray-700 flex items-center space-x-2"
          onClick={() => console.log("All Products clicked")}
        >
          <span>All Products</span>
          <MdOutlineArrowCircleRight className="w-4 h-4" />
        </a> */}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {productList.map((product) => (
          <ProductItem key={product._id} {...product} />
        ))}
      </div>
    </section>
  );
};

export default ProductsSection;
