
import React, { useState, useEffect } from "react";
import { FaCreditCard } from "react-icons/fa";
import {
  SiVisa,
  SiMastercard,
  SiAmericanexpress,
  SiGooglepay,
} from "react-icons/si";
import { MdOutlineArrowCircleLeft } from "react-icons/md";
import { startTransition } from "react";
import { useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Appearance } from "@stripe/stripe-js";
import CheckoutForm from "../auth/CheckoutForm";
import axios from "axios";

const stripePromise = loadStripe(
  "pk_test_51NnOPuKKd21nH4FVnc4A8LysAPcOEjnjSwHqn3vhTg8K01Ft6HfRKGLRaP7gLti1oveaA11w6fiSeU0LwPU6gT5o00PhHoAIo6"
);

interface OrderItem {
  name: string;
  price: number;
  image: string;
}

interface PaymentPageProps {
  orderItems?: OrderItem[];
  total?: number;
}

const PaymentPage: React.FC<PaymentPageProps> = ({ orderItems, total }) => {
  const navigate = useNavigate();
  const [displayTotal, setDisplayTotal] = useState<number>(0);
  const [orderId, setOrderId] = useState<string | null>(null);
  const [paymentSecret, setPaymentSecret] = useState<string | null>(null);
  const [elementOptions, setElementOptions] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState<any>(null);
  const [orderData, setOrderData] = useState<{
    price: number;
    dateTime: string;
    productId: string;
    userId: string | null;
    quantity: number;
  }>({
    price: 0,
    dateTime: "",
    productId: "",
    userId: null,
    quantity: 1,
  });
  const storedTotal = localStorage.getItem("totalPrice");

  useEffect(() => {
    if (storedTotal) {
      setDisplayTotal(parseFloat(storedTotal));
    }
  }, []);

  const openBackward = () => {
    startTransition(() => {
      navigate("/");
    });
  };

  const placeOrder = async () => {
    let userId = localStorage.getItem("userId");
    const ProductId = localStorage.getItem("selectedProductId");
    try {
      const orderResponse = await axios.post(
        process.env.REACT_APP_API_URL + "/order/add",
        {
          price: storedTotal,
          productId: ProductId,
          userId: userId,
          dateTime: new Date().toISOString(),
        }
      );
      console.log("orderResponse-->>", orderResponse);
      if (orderResponse.data) {
        const paymentIntent = orderResponse.data.data.paymentIntent;
        const orderId = orderResponse.data.data.order._id;
        localStorage.setItem("orderId", orderId);

        setOrderId(orderId);
        setPaymentSecret(paymentIntent);
        setElementOptions({ clientSecret: paymentIntent });
        if (paymentIntent) {
          localStorage.setItem("paymentSecret", paymentIntent);
          setClientSecret(paymentIntent);
        } else {
          console.warn("Payment secret is null, not storing in localStorage");
        }
      }
    } catch (error) {
      console.error("Error placing order:", error);
    }
  };

  const demoOrderItems: OrderItem[] = [
    {
      name: "E Tokens",
      price: displayTotal,
      image:
        "https://th.bing.com/th/id/OIP.4c6tMX5HMDNCnBu-SVL67gAAAA?rs=1&pid=ImgDetMain",
    },
  ];

  const displayItems = orderItems || demoOrderItems;
  const appearance: Appearance = {
    theme: "stripe",
    variables: {
      colorText: "#495e26",
      fontFamily:
        'Pally, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      fontWeightNormal: "700",
      colorPrimary: "#495e26",
    },
    rules: {
      ".Input": {
        color: "#495e26",
        fontFamily: "inherit", // Will inherit the fontFamily from variables
        fontWeight: "700",
      },
      ".Label": {
        color: "#495e26",
        fontFamily: "inherit",
        fontWeight: "700",
      },
      ".Tab": {
        fontFamily: "inherit",
        fontWeight: "700",
      },
      ".TabLabel": {
        fontFamily: "inherit",
        fontWeight: "700",
      },
    },
  };

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-white text-gray-800">
      {/* Left Column */}
      <div className="w-full lg:w-1/2 p-4 md:p-6 lg:p-8 border-b lg:border-b-0 lg:border-r border-gray-200">
        <button
          onClick={openBackward}
          className="flex items-center text-gray-600 mb-6 lg:mb-8"
        >
          <MdOutlineArrowCircleLeft className="mr-2" size={24} />
          <span className="text-sm md:text-base">Back to shop</span>
        </button>

        <div className="mb-6 lg:mb-8">
          {displayItems.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-between mb-2 text-sm md:text-base"
            >
              <div className="flex items-center">
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-6 h-6 md:w-8 md:h-8 mr-2 object-cover rounded"
                />
                <span>{item.name}</span>
              </div>
              <span>${item.price.toFixed(2)}</span>
            </div>
          ))}

          <div className="border-t border-gray-200 pt-2 mt-4">
            <div className="flex justify-between text-xs md:text-sm">
              <span>Subtotal</span>
              <span>${displayTotal.toFixed(2)}</span>
            </div>
            <div className="flex justify-between font-bold text-base md:text-lg mt-2">
              <span>Total</span>
              <span>${displayTotal.toFixed(2)}</span>
            </div>
          </div>
        </div>

        <button
          onClick={placeOrder}
          className="w-full bg-[#495e26] text-white py-2 md:py-3 rounded-md flex items-center justify-center mt-4 text-sm md:text-base"
        >
          <FaCreditCard className="mr-2" />
          Initiate Payment
        </button>
      </div>

      {/* Right Column */}
      <div className="w-full lg:w-1/2 p-4 md:p-6 lg:p-8">
        {clientSecret && (
          <div className="mt-4 lg:mt-0">
            <Elements
              stripe={stripePromise}
              options={{ clientSecret, appearance }}
            >
              <CheckoutForm />
            </Elements>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentPage;
