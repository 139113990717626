import React, { useState, ChangeEvent, FormEvent } from "react";

interface FormData {
  name: string;
  email: string;
  message: string;
}

const ContactPage: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    setFormData({ name: "", email: "", message: "" });
  };

  return (
    <div className="min-h-screen bg-white flex flex-col items-center">
      <div className="w-full max-w-4xl px-4 py-8 sm:px-6 md:px-8">
        <div className="max-w-2xl mx-auto text-center">
          <h1 className="text-2xl sm:text-3xl font-bold text-black mb-4">
            Contact Us
          </h1>
          <p className="text-sm sm:text-base text-gray-600 mb-8 font-normal">
            Get a question or need some help? Get in touch. we'd love to hear from you.
            <br className="hidden sm:block" />
            email - luckshack534@gmail.com Phone +1 (254) 462-9763
          </p>
        </div>
        
        {/* Form container - centered with responsive width */}
        <div className="max-w-md mx-auto w-full">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-[#495e26]"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-[#E8E8E8] rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-[#495e26] focus:border-[#495e26] text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-[#495e26]"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-[#E8E8E8] rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-[#495e26] focus:border-[#495e26] text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium text-[#495e26]"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                value={formData.message}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-[#E8E8E8] rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-[#495e26] focus:border-[#495e26] text-sm"
              ></textarea>
            </div>
            <div>
              <button
                type="submit"
                className="w-full bg-[#495e26] text-white py-3 px-4 rounded-md hover:bg-[#3a4c1e] transition duration-300 text-sm font-medium"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
        
        <footer className="mt-16 sm:mt-24 text-center text-xs sm:text-sm text-black">
          Copyright 2024, luckshack All Rights Reserved.
        </footer>
      </div>
    </div>
  );
};

export default ContactPage;