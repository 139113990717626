import React from "react";
import { RouteProps } from "react-router-dom";
import { demoPagesMenu, pageLayoutTypesPagesMenu } from "../menu";
import DefaultAside from "../pages/_layout/_asides/DefaultAside";

const asides: RouteProps[] = [
  { path: "/login", element: null },
  { path: "/", element: null },
  { path: "/product-details", element: null },
  { path: "/signup", element: null },
  { path: "/contact", element: null },
  { path: "/shop", element: null },
  { path: "/FAQ", element: null },
  { path: "/terms", element: null },
  { path: "/payment", element: null },
  { path: "/user-verify", element: null },
  { path: "/login-page", element: null },
  { path: "/sign-up", element: null },
  { path: "/my-account", element: null },
  { path: "/reset-password", element: null },
  { path: "/forgot-password", element: null },
  { path: "/kyc-form", element: null },
  { path: "/order-history", element: null },
  { path: "/setting", element: null },
  { path: "/privacy-policy", element: null },
  { path: "/refund-&-cancellation-policy", element: null },

  //	{ path: demoPagesMenu.signUp.path, element: null },
  //{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
  { path: "*", element: <DefaultAside /> },
];

export default asides;
