import React, { useState, useEffect } from "react";
import { FiArrowLeftCircle } from "react-icons/fi";
import { CiCirclePlus } from "react-icons/ci";
import axios from "axios";
import { startTransition } from "react";
import { useNavigate } from "react-router-dom";
import BitcoinDropdown from "../dropDown"; // Importing new dropdown component

const UserVerify = () => {
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [legalName, setLegalName] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [productImg, setProductImg] = useState<any>(null);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);

  const navigate = useNavigate();

  const fetchProductImage = async () => {
    console.log("In fetchProductImage--", fetchProductImage);

    const ProductId = localStorage.getItem("selectedProductId");
    try {
      console.log("selectedProductId----", ProductId);

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/${ProductId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      console.log("response from fetch product image---", response.data);
      setProductImg(response.data.data);
    } catch (error: any) {
      console.error("Error fetching order :", error);
    }
  };

  useEffect(() => {
    fetchProductImage();
  }, []);

  const handleContinue = () => {
    console.log(
      "Email:",
      email,
      "User Name:",
      userName,
      "Legal Name:",
      legalName,
      "Agree to Terms:",
      agreeToTerms
    );
    startTransition(() => {
      navigate("/Payment");
    });
  };

  const handleGoBack = () => {
    startTransition(() => {
      navigate("/");
    });
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 font-sans">
      <div className="flex flex-col md:flex-row gap-8 w-full">
        <div className="md:w-1/2 flex items-start relative">
          <button
            onClick={handleGoBack}
            className="absolute top-0 left-0 m-2 text-gray-900 z-10"
          >
            <FiArrowLeftCircle size={24} />
          </button>
          {productImg && productImg.imageName ? (
            <img
              src={`https://s3.wasabisys.com/productimage/${productImg.imageName}`}
              alt={productImg.name || "Product Image"}
              className="w-full h-auto max-w-md mx-auto rounded-lg"
              style={{ backgroundColor: "#f0d889" }}
            />
          ) : (
            <span className="no-image">No Image</span>
          )}
        </div>

        <div className="md:w-1/2 space-y-6">
          <h1 className="text-2xl font-bold">E Tokens</h1>

          <div className="space-y-4 border-b border-gray-900 pb-2">
            <div className="space-y-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Email
              </label>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border rounded-md placeholder-lime-900 focus:outline-none focus:lime-2 focus:ring-white focus:ring-opacity-50"
              />

              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Username
              </label>
              <input
                type="text"
                placeholder="User Name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                className="w-full p-2 border rounded-md placeholder-lime-900 rounded-md focus:outline-none focus:lime-2 focus:ring-white focus:ring-opacity-50"
              />

              <label
                htmlFor="legalname"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Legal Name
              </label>
              <input
                type="text"
                placeholder="Legal Name"
                value={legalName}
                onChange={(e) => setLegalName(e.target.value)}
                className="w-full p-2 border rounded-md placeholder-lime-900 focus:outline-none focus:ring-lime-900"
              />

              <BitcoinDropdown />

              <label
                htmlFor="agreeToTerms"
                className="flex items-center cursor-pointer"
              >
                <div className="relative mr-2">
                  <input
                    type="checkbox"
                    id="agreeToTerms"
                    checked={agreeToTerms}
                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                    className="sr-only"
                  />
                  <div
                    className={`block w-10 h-6 rounded-full ${
                      agreeToTerms ? "bg-[#495e26]" : "bg-gray-300"
                    }`}
                  ></div>
                  <div
                    className={`dot absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition-transform duration-200 ease-out ${
                      agreeToTerms ? "transform translate-x-4" : ""
                    }`}
                  ></div>
                </div>
                <span className="text-sm">
                  I agree to the
                  <a
                    href="/terms"
                    target="_blank"
                    className="text-gray-900  ml-1"
                  >
                    terms and conditions
                  </a>
                </span>
              </label>
            </div>

            <div className="flex space-x-2">
              <button
                type="submit"
                onClick={handleContinue}
                className="w-full bg-[#495e26] text-white py-3 px-4 rounded-md hover:bg-[#3e501f] transition duration-300 text-sm"
              >
                Continue
              </button>
            </div>
          </div>

          <div className="space-y-4">
            <div className="border-b border-gray-900 pb-2">
              <button
                onClick={() => setIsDescriptionOpen(!isDescriptionOpen)}
                className="flex items-center w-full text-left"
              >
                <CiCirclePlus
                  size={16}
                  className="text-gray-900 mr-2 flex-shrink-0"
                />
                <span className="text-sm">Description</span>
              </button>
              {isDescriptionOpen && (
                <div className="mt-2 text-xs text-gray-600">
                  <p>This is the product description.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserVerify;