import React, { useState, useRef, useEffect } from "react";
import upload from "../../../icons/upload.svg";
import user from "../../../icons/user.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { startTransition } from "react";

const KYCVerification: React.FC = () => {
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [files, setFiles] = useState<File[]>([]);
  const [photoTaken, setPhotoTaken] = useState<boolean>(false);
  const [photoURL, setPhotoURL] = useState<string>("");
  const videoRef = useRef<HTMLVideoElement>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const fileList = Array.from(e.target.files).map((file) => file);
      setFiles(fileList);
    }
  };
  const navigate = useNavigate();
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      const fileList = Array.from(e.dataTransfer.files).map((file) => file);
      setFiles(fileList);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      streamRef.current = stream;
      setIsCameraOn(true);
    } catch (err) {
      console.error("Error accessing camera:", err);
    }
  };

  const stopCamera = () => {
    //if (streamRef.current) {
    //  streamRef.current.getTracks().forEach((track) => track.stop());
    //}
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null; // Clear the stream reference
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null; // Disconnect the video stream
    }
    setIsCameraOn(false);
  };

  const capturePhoto = () => {
    const video = videoRef.current;
    if (video) {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(video, 0, 0);
        setPhotoURL(canvas.toDataURL("image/jpeg"));
        setPhotoTaken(true);
        stopCamera();
      }
    }
  };

  const retakePhoto = () => {
    setPhotoTaken(false);
    setPhotoURL("");
    startCamera();
  };

  const handleNextStep = async () => {
    console.log("Active step:", activeStep);
    if (activeStep === 1 && files.length > 0) {
      setActiveStep(2);
      startCamera();
    } else if (activeStep === 2 && photoTaken) {
      console.log("Inside Submitting verification...", activeStep);
      setIsLoading(true);
      //stopCamera();
      const userId = localStorage.getItem("userId");
      const formData = new FormData();
      const blob = await fetch(photoURL).then((res) => res.blob());
      formData.append("photo", blob, "photo.png");
      formData.append("document", files[0], "document.png");
      stopCamera();
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + `/user/kyc-verification/${userId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.success === true) {
          navigate("/payment");
        } else {
          toast.error("Verification failed. Please try again.");
        }
      } catch (error) {
        console.error("Error during verification:", error);
        toast.error("An error occurred while submitting verification.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleBack = (Tab: any) => {
    console.log(Tab);
    if (Tab === "Cancel") {
      startTransition(() => {
        navigate("/");
      }); // Re
    } else if (activeStep === 2) {
      stopCamera();
      setActiveStep(1);
    }
  };

  useEffect(() => {
    if (activeStep === 2 && !photoTaken) {
      startCamera();
    }
    return () => stopCamera();
  }, [activeStep]);

  const renderStep1 = () => (
    <>
      <div className="mb-4">
        <h3 className="text-sm font-medium mb-2">
          Step 1 of 2: Upload Your Document
        </h3>
        <p className="text-xs text-gray-500 mb-4">
          Upload a file from your device. Supported formats: PDF, JPEG, PNG.
        </p>
      </div>

      <div
        className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {/* <svg
          className="mx-auto mb-4 text-gray-400 w-8 h-8"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
          />
        </svg> */}

        <img
          className="mx-auto mb-4 text-gray-400 w-8 h-8"
          alt="upload"
          src={upload}
        ></img>
        <p className="text-sm text-gray-600 mb-2">
          Drag your files to start uploading
        </p>
        <p className="text-sm text-gray-500 mb-4">OR</p>
        <label className="inline-block">
          <input
            type="file"
            className="hidden"
            onChange={handleFileChange}
            accept=".pdf,.jpg,.jpeg,.png"
            multiple
          />
          <span className="px-4 py-2 bg-[#495e26] text-white rounded-lg cursor-pointer hover:bg-[#495e26] text-sm">
            Browse Files
          </span>
        </label>
      </div>

      {files.length > 0 && (
        <div className="mt-4">
          {files.map((file, index) => (
            <div
              key={index}
              className="flex items-center justify-between bg-gray-50 p-3 rounded-lg mb-2"
            >
              <div className="flex items-center">
                <div className="bg-gray-200 p-2 rounded mr-3">
                  {/* <svg
                    className="w-4 h-4 text-gray-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg> */}
                </div>
                <div>
                  <p className="text-sm font-medium">{file.name}</p>
                  <p className="text-xs text-gray-500">
                    {(file.size / (1024 * 1024)).toFixed(2)} MB
                  </p>
                </div>
              </div>
              <button
                onClick={() => handleRemoveFile(index)}
                className="text-gray-400 hover:text-gray-600"
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          ))}
        </div>
      )}
    </>
  );

  const renderStep2 = () => (
    <>
      <div className="mb-4">
        <h3 className="text-sm font-medium mb-2">
          Step 2 of 2: Live Photo Capture
        </h3>
        <p className="text-xs text-gray-500 mb-4">
          Please take a clear photo of yourself for verification.
        </p>
      </div>

      <div className="relative">
        {!photoTaken ? (
          <div className="border rounded-lg overflow-hidden">
            <div className="relative">
              <video
                ref={videoRef}
                autoPlay
                playsInline
                className="w-full h-64 object-cover"
              />
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-48 h-48 border-4 border-white rounded-full opacity-50" />
                {/* <svg
                  className="absolute text-white opacity-50 w-16 h-16"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg> */}
                <img className="absolute w-16 h-16" alt="user" src={user} />
              </div>
            </div>
            <div className="p-4 bg-gray-50 flex justify-center">
              <button
                onClick={capturePhoto}
                className="flex items-center px-4 py-2 bg-[#495e26] text-white rounded-lg hover:bg-[#495e26] text-sm"
              >
                <svg
                  className="w-4 h-4 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                Take Photo
              </button>
            </div>
          </div>
        ) : (
          <div className="border rounded-lg overflow-hidden">
            <div className="relative">
              <img
                src={photoURL}
                alt="Captured photo"
                className="w-full h-64 object-cover"
              />
            </div>
            <div className="p-4 bg-gray-50 flex justify-center">
              <button
                onClick={retakePhoto}
                className="flex items-center px-4 py-2 bg-[#495e26] text-white rounded-lg hover:bg-[#495e26] text-sm"
              >
                <svg
                  className="w-4 h-4 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                Retake Photo
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div className="max-w-md mx-auto bg-white rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">
          Complete Your KYC Verification
        </h2>
        <button
          className="text-gray-500 hover:text-gray-700"
          onClick={() => {
            handleBack("Cancel");
          }}
        >
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className="flex justify-center mb-8">
        <div className="flex items-center">
          <div
            className={`w-8 h-8 rounded-full flex items-center justify-center ${
              activeStep >= 1 ? "bg-[#495e26]" : "bg-gray-200"
            } text-white`}
          >
            1
          </div>
          <div
            className={`w-16 h-1 ${
              activeStep >= 2 ? "bg-[#495e26]" : "bg-gray-200"
            }`}
          />
          <div
            className={`w-8 h-8 rounded-full flex items-center justify-center ${
              activeStep >= 2 ? "bg-[#495e26]" : "bg-gray-200"
            } text-white`}
          >
            2
          </div>
        </div>
      </div>

      {activeStep === 1 ? renderStep1() : renderStep2()}

      <div className="flex justify-between mt-6">
        <button
          className="px-4 py-2 text-gray-600 hover:text-gray-800 text-sm"
          onClick={() => handleBack(activeStep === 1 ? "Cancel" : "Back")}
        >
          {activeStep === 1 ? "Cancel" : "Back"}
        </button>
        <button
          className={`px-4 py-2 text-white rounded-lg text-sm ${
            (activeStep === 1 && files.length > 0) ||
            (activeStep === 2 && photoTaken)
              ? "bg-[#495e26] hover:bg-[#495e26]"
              : "bg-gray-400 cursor-not-allowed"
          }`}
          onClick={handleNextStep}
          disabled={
            isLoading ||
            (!(activeStep === 1 && files.length > 0) &&
              !(activeStep === 2 && photoTaken))
          }
        >
          {isLoading ? (
            <span className="loader"></span>
          ) : activeStep === 1 ? (
            "Next"
          ) : (
            "Submit"
          )}
        </button>
      </div>
      {isLoading && (
        <div className="flex justify-center mt-4">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default KYCVerification;
