import React, { useState } from "react";

const bitcoinOptions = [
  { value: "btc", label: "Bitcoin (BTC)" },
  { value: "bch", label: "Bitcoin Cash (BCH)" },
  { value: "btg", label: "Bitcoin Gold (BTG)" },
];

const CustomBitcoinDropdown = () => {
  const [selectedBitcoin, setSelectedBitcoin] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (value: any) => {
    setSelectedBitcoin(value);
    setIsOpen(false);
  };

  return (
    <div className="w-full max-w-xs relative">
      <label htmlFor="system" className="flex items-center cursor-pointer">
        System
      </label>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#495e26] focus:border-[#495e26] flex justify-between"
      >
        {selectedBitcoin
          ? bitcoinOptions.find((option) => option.value === selectedBitcoin)
              ?.label
          : "Select Bitcoin"}
        <span className="ml-2">&#9662;</span>
      </button>
      {isOpen && (
        <ul className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-md mt-1 z-10">
          {bitcoinOptions.map((option) => (
            <li
              key={option.value}
              onClick={() => handleSelect(option.value)}
              className="px-4 py-2 hover:bg-[#e2f1c1] cursor-pointer"
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomBitcoinDropdown;
