import React, { useState, useEffect } from 'react';
import axios from "axios";

const UserDetails = () => {
    const [userInfo, setUserInfo] = useState<any[]>([]);

    const fetchUser = async () => {
        let userId = localStorage.getItem('userId');
        console.log("userId from  fetchUser---", userId);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/${userId}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                }
            });
            console.log("response from user---", response.data.data);
            setUserInfo(response.data.data);
        } catch (error: any) {
            console.error('Error fetching user details :', error);
        }
    }
    useEffect(() => {

        fetchUser();
    }, []);
    return (
        <>
            <h3>
                Hi user_name

            </h3>
        </>
    )
}
export default UserDetails;