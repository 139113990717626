import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-white flex flex-col items-center">
      <div className="w-full max-w-4xl px-4 py-8 sm:px-6 md:px-8">
        <div className="max-w-2xl mx-auto">
          <div className="mb-6 sm:mb-8">
            <h1 className="text-2xl sm:text-3xl font-bold text-black mb-4">
              Privacy Policy
            </h1>
          </div>

          <div className="bg-[#F9F9F9] border border-[#E8E8E8] rounded-md p-4 sm:p-6 mb-4 hover:border-[#495e26] transition-colors duration-200">
            <div className="space-y-4">
              <p className="text-sm sm:text-base text-[#495e26] leading-relaxed">
                Luckshack values and protects the privacy of its users. We collect
                only the information necessary to provide our services, which
                includes usernames, full names, and email addresses. This
                information is stored securely and is used solely for the purpose of
                account management and customer communication.
              </p>
              <p className="text-sm sm:text-base text-[#495e26] leading-relaxed">
                We do not sell or share any personal data with third parties, except
                where required by law. Players have the right to request the deletion
                or modification of their personal data by contacting customer support.
              </p>
              <p className="text-sm sm:text-base text-[#495e26] leading-relaxed">
                We employ strict security measures to safeguard personal data and
                protect it from unauthorized access.
              </p>
            </div>
          </div>
        </div>

        <footer className="mt-16 sm:mt-24 text-center text-xs sm:text-sm text-black">
          Copyright 2024, luckshack All Rights Reserved.
        </footer>
      </div>
    </div>
  );
};

export default PrivacyPolicy;