// import React, { useState, useEffect } from 'react';
// import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
// import { StripePaymentElementOptions } from '@stripe/stripe-js';
// import axios from "axios";
// import { useNavigate } from 'react-router-dom';

// const CheckoutForm = () => {
//   const stripe = useStripe();
//   const navigate = useNavigate();
//   const elements = useElements();
//   console.log("Stripe value in checkout form:", stripe);
//   const [productImg, setProductImg] = useState<any>(null);
//   const [orderId, setOrderId] = useState(localStorage.getItem('orderId'));

//   const handleSubmit = async (event: any) => {
//     event.preventDefault();
//     console.log("Form Submitted");
//     if (!stripe || !elements) {
//       return;
//     }
//     console.log("element--->>",elements)
//     const { error, paymentIntent } = await stripe.confirmPayment({
//       elements,
//       confirmParams: {
//         return_url: `${window.location.origin}/completion`,
//       },
//       redirect: "if_required",
      
//     });

//     if (error) {
//       console.log("Error in payment: " + error.message);
//     } else if (paymentIntent && paymentIntent.status === "succeeded") {
//       alert("Payment status: " + paymentIntent.status);
//       console.log("Order id for update order--->>",orderId);
      
//       await updateOrderPaymentStatus(orderId, "Success");

//       navigate("/");
//     }
//   };
//   const updateOrderPaymentStatus = async (orderId: string | null, status: string) => {
//     if (!orderId) {
//       console.error("Order ID is missing");
//       return;
//     }

//     try {
//       const response = await axios.put(`${process.env.REACT_APP_API_URL}/order/${orderId}`, {
//         paymentStatus: status,
//       });
//       console.log('Order payment status updated successfully:', response.data.data);
//     } catch (error: any) {
//       console.error('Error updating order payment status:', error);
//     }
//   };
//   const fetchProductImage = async () => {
//     const ProductId = localStorage.getItem("selectedProductId");

//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_URL}/product/${ProductId}`, {
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//           "Access-Control-Allow-Origin": "*",
//         },
//       });
//       setProductImg(response.data.data);
//     } catch (error: any) {
//       console.error('Error fetching order:', error);
//     }
//   };
// const handleBack = () => {
//   navigate ("/product-details");
// }
//   useEffect(() => {
//     fetchProductImage();
//   }, []);

//   /* const paymentElementOptions: StripePaymentElementOptions = {
//      style: {
//        base: {
//          color: "white",  // Set label and input text color to white
//          fontSize: "16px",
//          '::placeholder': {
//            color: '#aab7c4',
//          },
//        },
//        invalid: {
//          color: '#fa755a',
//        },
//      },
//    }; */
//    const paymentElementOptions = {
//     /*  
//     layout: 'tabs', // or 'accordion', 'none' based on your needs
//     style: {
//       base: {
//         color: '#FFFFFF', // Text color
//         fontSize: '16px', // Font size
//         fontFamily: 'Arial, sans-serif', // Font family
//         '::placeholder': {
//           color: '#B1B1B1', // Placeholder text color
//         },
//       },
//       invalid: {
//         color: '#FF0000', // Color for invalid input
//         iconColor: '#FF0000', // Icon color for invalid input
//       },
//     },
//     */
//   };
//     return (
//     <div style={{
//     //  width: '90vw',
//       height: '90vh',
//      // backgroundColor: 'black',
//       display: 'flex',
//       color: "white",
//       justifyContent: 'center',
//       alignItems: 'center',
//       margin: "50px",
//       border: '10px solid gray',
//       borderRadius: '10px',
//     }}>
//       <div style={{ display: 'flex', width: '100%', height: '100%' }}>
//         {/* Left part (Image) */}
        
//   {/*}      <div
//           className="w-1/2 p-6"
//           style={{
//             backgroundColor: '#f0f0f0',
//             display: 'flex',
//             justifyContent: 'center',
//           }}
//         >
//           {productImg && productImg.imageName ? (
//             <img
//               src={`https://s3.wasabisys.com/productimage/${productImg.imageName}`}
//               style={{
//                 height: '400px',
//                 width: '400px',
//                 padding: "30px"
//               }}
//             />
//           ) : (
//             <span className="no-image">No Image</span>
//           )}
//         </div>
//         */} 
//         {/* Right part (Form) */}
//         <div style={{
//           marginLeft: '10px',
//           padding: '20px',
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//           height: '100%',
//         }}>
          
//           <div >
//             <form onSubmit={handleSubmit}>
//               <PaymentElement/>
//               <div className="flex justify-between mt-6">

//                 <button
//                    onClick={handleBack}
//                   style={{
//                     width: '160px',
//                     height: '45px',
//                     padding: '10px',
//                     backgroundColor: 'white',
//                     borderRadius: '4px',
//                     fontSize: '18px',
//                     cursor: 'pointer',
//                     color: "black"
//                   }}
//                 >
//                   Back
//                 </button>
//                 <button disabled={!stripe}
//                onClick={handleSubmit}
//                type='submit'
//                 className="w-40 py-2 bg-green-600 text-white rounded-md"
//                 >
//                   Submit
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CheckoutForm;



//2nd draft

import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import type {
  StripeElements,
  Stripe,
  PaymentIntent,
  StripePaymentElementOptions,
} from "@stripe/stripe-js";
import { BsCreditCard2Front } from "react-icons/bs";
import { BiBuilding } from "react-icons/bi";
import { SiCashapp } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import axios from "axios";
  import { toast } from 'react-toastify';

// Type definitions
type PaymentMethodType = "card" | "bank" | "cash-app";

interface TabButtonProps {
  active: boolean;
  icon: React.ComponentType<{ size?: number; className?: string }>;
  text: string;
  onClick: () => void;
}

interface OrderUpdateResponse {
  data: {
    data: any; // Replace with your specific order data type
    message: string;
  };
}

const CheckoutForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>("card");
  const [orderId] = useState<string | null>(localStorage.getItem("orderId"));

  const updateOrderPaymentStatus = async (
    orderId: string | null,
    status: string
  ): Promise<void> => {
    if (!orderId) {
      console.error("Order ID is missing");
      return;
    }

    try {
      const response = await axios.put<OrderUpdateResponse>(
        `${process.env.REACT_APP_API_URL}/order/${orderId}`,
        { paymentStatus: status }
      );
      console.log(
        "Order payment status updated successfully:",
        response.data.data
      );
    } catch (error) {
      console.error("Error updating order payment status:", error);
    }
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    if (!stripe || !elements) return;

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`,
      },
      redirect: "if_required",
    });

    if (error) {
      console.log("Error in payment: " + error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      toast.success("Payment Successfully");
      await updateOrderPaymentStatus(orderId, "Success");
      navigate("/");
    }
  };

  const handleBack = (): void => {
    navigate("/product-details");
  };
  const paymentElementOptions: StripePaymentElementOptions = {
    layout: { type: "tabs", defaultCollapsed: false },
    defaultValues: {
      billingDetails: {
        name: "Auto-fill name", // Optional: Remove if not needed
      },
    },
    // Business name that appears in the payment popup
    business: {
      name: "Your Business Name", // Optional: Replace with your business name
    },
  };
  const TabButton: React.FC<TabButtonProps> = ({
    active,
    icon: Icon,
    text,
    onClick,
  }) => (
    <button
      type="button"
      onClick={onClick}
      className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-all ${
        active
          ? "bg-green-700 text-white"
          : "bg-white text-gray-700 hover:bg-gray-100"
      }`}
    >
      <Icon size={20} className={active ? "text-white" : "text-gray-700"} />
      <span>{text}</span>
    </button>
  );

  return (
    <div className="min-h-screen p-8">
      <div className="max-w-3xl mx-auto bg-white  p-6">
        {/* Header Tabs */}
        {/* <div className="flex gap-4 mb-8 border-b pb-4">
          <TabButton
            active={paymentMethod === 'card'}
            icon={BsCreditCard2Front}
            text="Card"
            onClick={() => setPaymentMethod('card')}
          />
          <TabButton
            active={paymentMethod === 'bank'}
            icon={BiBuilding}
            text="Bank"
            onClick={() => setPaymentMethod('bank')}
          />
          <div className="ml-auto">
            <TabButton
              active={paymentMethod === 'cash-app'}
              icon={SiCashapp}
              text="Cash App Pay"
              onClick={() => setPaymentMethod('cash-app')}
            />
          </div>
        </div> */}

        {/* Search Bar (for Bank tab) */}
        {paymentMethod === "bank" && (
          <div className="mb-6">
            <div className="relative">
              <input
                type="text"
                placeholder="Search for your bank"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              />
              <div className="absolute right-3 top-2.5 text-gray-400">
                <BsCreditCard2Front size={20} />
              </div>
            </div>
          </div>
        )}

        {/* Payment Form */}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="bg-white rounded-lg">
            <PaymentElement
              className="text-lime-900"
              options={paymentElementOptions}
            />
          </div>

          {/* Action Buttons */}
          <div className="flex justify-between pt-4">
            <button
              type="button"
              onClick={handleBack}
              className="px-6 py-2 border w-1/2 mx-2 border-gray-300 hover:bg-gray-50 text-gray-700 rounded-lg transition-colors"
            >
              Back
            </button>
            <button
              type="submit"
              disabled={!stripe}
              className="px-6 py-2 w-1/2 bg-lime-900 hover:bg-lime-700 text-white rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Submit
            </button>
          </div>
        </form>

        {/* Terms Text */}
        <p className="text-sm text-gray-500 mt-6 text-center">
          Get $5 back when you pay with your bank. See terms.
        </p>
      </div>
    </div>
  );
};

export default CheckoutForm;
