import React from "react";

const RefundPolicy = () => {
  return (
    <div className="min-h-screen bg-white flex flex-col items-center">
      <div className="w-full max-w-4xl px-4 py-8 sm:px-6 md:px-8">
        <div className="max-w-2xl mx-auto">
          <div className="mb-6 sm:mb-8">
            <h1 className="text-2xl sm:text-3xl font-bold text-black mb-4">
              Refund and Cancellation Policy
            </h1>
          </div>

          <div className="bg-[#F9F9F9] border border-[#E8E8E8] rounded-md p-4 sm:p-6 mb-4 hover:border-[#495e26] transition-colors duration-200">
            <div className="space-y-4">
              <p className="text-sm sm:text-base text-[#495e26] leading-relaxed">
                At Luckshack, all sales are final. Once a purchase has been made and
                confirmed, we do not offer refunds or cancellations under any
                circumstances. This policy applies to all transactions made through
                our platform.
              </p>
              <p className="text-sm sm:text-base text-[#495e26] leading-relaxed">
                By proceeding with a purchase, players acknowledge that they have
                fully reviewed the services being offered and understand that all
                sales are non-refundable. We advise all players to double-check
                their purchases prior to confirming any transaction.
              </p>
              <p className="text-sm sm:text-base text-[#495e26] leading-relaxed">
                Any inquiries regarding billing or disputes will be reviewed by our
                support team, but no refunds will be issued.
              </p>
            </div>
          </div>
        </div>

        <footer className="mt-16 sm:mt-24 text-center text-xs sm:text-sm text-black">
          Copyright 2024, luckshack All Rights Reserved.
        </footer>
      </div>
    </div>
  );
};

export default RefundPolicy;