
import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { Suspense, useTransition } from "react";
import { FiArrowLeftCircle } from "react-icons/fi";
import axios from "axios";
import { startTransition } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../contexts/userContext";
import { FiEye, FiEyeOff } from "react-icons/fi";
// Define the structure of the form data using an interface
interface LoginForm {
  email: string;
  password: string;
 // terms: boolean;
}
const Login: React.FC = () => {
  const navigate = useNavigate();
  const { login } = useUser();
  const [formError, setFormError] = useState<any>({});
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState<LoginForm>({
    email: "",
    password: "",
  //  terms: false,
  });

  // Handle changes in input fields
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    if (formError[name]) {
      setFormError((prevErrors: any) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.password) newErrors.password = "Password is required";
    // Commented out the terms validation
    // if (!formData.terms) newErrors.terms = "You must agree to the Terms of Use";

    setFormError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the form from submitting the traditional way
    if (!validateForm()) return;

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/login",
        formData
      );
      console.log("response from login---",response.data)
      startTransition(() => {
        const token = localStorage.setItem("Token", response.data);
        const userId = response.data.user_id;
        localStorage.setItem("userId", userId);
        const kycVerified = response.data.KycVerified;
       // login();
        console.log("userId from login---", userId);
        console.log("kycVerified---", kycVerified);

        if (kycVerified) {
          navigate("/payment");
        } else {
          navigate("/kyc-form");
        }
        navigate("/payment");
      });
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        setFormError((prevErrors: any) => ({
          ...prevErrors,
          login: "Invalid Email and Password",
        }));
      }
    }
  };

  return (
    <div className="">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Login</h2>
        <a
          href="/sign-up"
          className="text-sm text-olive-600 hover:underline font-bold"
        >
          Sign up
        </a>
      </div>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-lime-900 mb-1"
          >
            Email
          </label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-3 py-2 border-1 border-lime-900 rounded-md focus:outline-none focus:ring-1 focus:ring-lime-500"
          />
          {formError.email && (
            <p className="text-red-500 text-sm mt-1">{formError.email}</p>
          )}
        </div>
        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-lime-900 mb-1"
          >
            Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full px-3 py-2 border-1 border-lime-900 rounded-md focus:outline-none focus:ring-1 focus:ring-lime-500"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
            >
              {showPassword ? <FiEyeOff size={20} /> : <FiEye size={20} />}
            </button>
          </div>
          {formError.password && (
            <p className="text-red-500 text-sm mt-1">{formError.password}</p>
          )}
        </div>
        <button
          style={{ background: "#556B2F", borderRadius: "20px" }}
          type="submit"
          className="w-full py-2 px-4 hover:bg-olive-700 text-white font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-olive-500"
        >
          Login
        </button>
      </form>
    </div>
  );
};

const LoginPage: React.FC = () => {
  //const [isPending, startTransition] = useTransition();

  const [quantity, setQuantity] = useState<string>("10");
  const [couponCode, setCouponCode] = useState("");
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [productImg, setProductImg] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false); // Static value for login status

  const fetchProductImage = async () => {
    const ProductId = localStorage.getItem("selectedProductId");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/${ProductId}`
      );
      //   startTransition(() => {
      setProductImg(response.data.data);
      //  });
    } catch (error: any) {
      console.error("Error fetching order :", error);
    }
  };

  useEffect(() => {
    fetchProductImage();
  }, []);

  const navigate = useNavigate();

  const openContinue = () => {
    //startTransition(() => {
    navigate("/user-verify");
    //});
  };

  const openBackward = () => {
    //startTransition(() => {
    navigate("/product-details");
    //});
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 font-sans py-8">
      <div className="flex flex-col md:flex-row gap-8 w-full">
        {/* Product Image Section */}
        <div className="md:w-1/2 flex items-start relative">
          <button
            onClick={openBackward}
            className="absolute top-0 left-0 m-2 text-gray-900 z-10"
          >
            <FiArrowLeftCircle size={24} />
          </button>
          {productImg && productImg.imageName ? (
            <img
              src={`https://s3.wasabisys.com/productimage/${productImg.imageName}`}
              alt={productImg.name || "Product Image"}
              className="w-full h-auto max-w-md mx-auto rounded-lg object-cover"
              style={{ backgroundColor: "#f0d889" }}
            />
          ) : (
            <span className="no-image text-center w-full text-gray-500">
              No Image
            </span>
          )}
        </div>
        {/* Login Form Section */}
        <Suspense>
          <div className="md:w-1/2 w-full mt-8 md:mt-0 flex justify-center">
            {!isUserLoggedIn && (
              <div className="w-full max-w-lg mx-auto p-4 ">
                <Login />
              </div>
            )}
          </div>
        </Suspense>
      </div>
    </div>
  );
};

export default LoginPage;
