import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { useParams, useNavigate } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
    CardElement,
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    AddressElement
} from '@stripe/react-stripe-js';
import { PaymentIntentResult } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import PaymentPage from '../payment/payment';
const stripePromise = loadStripe('pk_test_51NnOPuKKd21nH4FVnc4A8LysAPcOEjnjSwHqn3vhTg8K01Ft6HfRKGLRaP7gLti1oveaA11w6fiSeU0LwPU6gT5o00PhHoAIo6');
type StripeAddressElementOptions = {
    style?: {
        base?: {
            color?: string;
            fontFamily?: string;
            fontSmoothing?: string;
            fontSize?: string;
            height?: string;
            width?: string;
            padding?: string;
            border?: string;
            borderRadius?: string;
            "::placeholder"?: {
                color?: string;
            };
        };
        invalid?: {
            color?: string;
            iconColor?: string;
        };
    };
    mode?: "shipping" | "billing"; // Only accepts 'shipping' or 'billing'
};
const Payment: React.FC = () => {
    const stripe = useStripe();
    const elements = useElements();

    const navigate = useNavigate();
    const { productId } = useParams();

    const [currentStep, setCurrentStep] = useState(1);
    const [orderId, setOrderId] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [paymentError, setPaymentError] = useState<string | null>(null);
    const [paymentSecret, setPaymentSecret] = useState<string | null>(null);
    const [elementOptions, setElementOptions] = useState<any>(null);
    const [productImg, setProductImg] = useState<any>(null);
    const [userData, setUserData] = useState({
       firstName: "",
       lastName: "",
        phone: "",
        email: "",
        system: ""
    });
    const [orderData, setOrderData] = useState<{
        price: number;
        dateTime: string;
        productId: string;
        userId: string | null;
        quantity: number;
    }>({
        price: 0,
        dateTime: '',
        productId: '',
        userId: null,
        quantity: 1,
    });
    console.log("in payment====");

    const clientSecret = localStorage.getItem('paymentSecret') || '';
    console.log("Client secreate--------->>",paymentSecret);
    
    const fetchProductImage = async () => {
        const ProductId = localStorage.getItem("selectedProductId");

        try {
            console.log("selectedProductId----", ProductId);

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/product/${ProductId}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                }
            });
            console.log("response from fetch prodct image---", response.data.data);

            setProductImg(response.data.data);
        } catch (error: any) {
            console.error('Error fetching order :', error);
        }
    };
    useEffect(() => {
        if (productId) {
            setOrderData((prev) => ({ ...prev, productId }));
        }
    }, [productId]);
    useEffect(() => {
        const storedProductId = localStorage.getItem("selectedProductId");

        if (storedProductId) {
            setOrderData((prev) => ({
                ...prev,
                productId: storedProductId,
            }));
        }
        fetchProductImage();
    }, []);
    if (!clientSecret) {
        return <div>Error: Payment secret not found. Please try again.</div>;
    }
    // Handle going back to step 1

    return (
        <>
            <CheckoutForm />

        </>

    );
};
const WrappedPayment: React.FC = () => (
    // <Elements stripe={stripePromise} options={{ appearance:{theme: 'night'}, clientSecret: localStorage.getItem('paymentSecret') || '' } }>

    //     <Payment />
    // </Elements>

    <><PaymentPage/></>
);
export default WrappedPayment;


