import React, { createContext, ReactNode, useState, useContext, useEffect } from 'react';

// Create a context for managing login state
interface UserContextType {
  isLoggedIn: boolean;
  login: () => void;
  logout: () => void;
}
const UserContext = createContext<UserContextType | undefined>(undefined);

// Create a provider to wrap your app with
export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserId = () => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    checkUserId(); // Check login status on mount
  }, []);

  const login = () => {
    setIsLoggedIn(true); // Update the state when logged in
  };

  const logout = () => {
    localStorage.removeItem("userId");
  console.log("userId removed from localStorage");

  localStorage.removeItem("Token");
  console.log("Token removed from localStorage");

   // localStorage.removeItem('userId');
   // localStorage.removeItem('Token');
    setIsLoggedIn(false); // Update the state when logged out
  };

  return (
    <UserContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

// Hook to use the UserContext in any component
//export const useUser = () => useContext(UserContext);

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {

    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
