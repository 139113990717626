import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

const ResetPassword: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [formError, setFormError] = useState<string>("");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    if (!password) {
      setFormError("Password is required");
      return;
    }

    try {
      // Submit the new password and token to backend
      await axios.post(process.env.REACT_APP_API_URL +'/user/reset-password', { token : searchParams.get("token"), newPassword: password });
      navigate('/login');
    } catch (error) {
      setFormError("Failed to reset password");
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen p-4">
      <div className="flex flex-col bg-white rounded-lg w-full max-w-lg p-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">Reset Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <label htmlFor="password" className="block text-sm font-medium text-gray-600">
              New Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded-md"
            />
            {formError && <p className="text-red-500">{formError}</p>}
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-lime-700 text-white rounded-md mt-4"
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
