import React from "react";
import Logo from "../../../icons/Logo.png";
const Footer: React.FC = () => {
  return (
    <footer className="flex flex-col md:flex-row justify-between items-start md:items-center py-8  w-full px-6">
      <div className="mb-4 md:mb-0 text-center md:text-left">
        <img
          style={{ height: 60 }}
          src={Logo}
          alt="Luck Shack Logo"
          className="w-auto object-contain"
        />
        <p className="text-sm text-gray-600">
          &copy; 2024 luckshack All Rights Reserved.
        </p>
      </div>
      <div>
        <h3 className="mb-2">Quick Links</h3>
        <ul className="space-y-1">
          {[
            // "Shop",
            "Refund & Cancellation Policy",
            "Privacy Policy",
            "Terms",
          ].map((item) => (
            <li key={item}>
              <a
                href={item.toLowerCase().replace(/ /g, "-")}
                className="text-sm text-gray-600 hover:text-gray-900"
              >
                {item}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
