import React, { useState } from "react";
import { OrderHistory } from "../dashboard/adminDash";
import { OrderDetails } from "../dashboard/adminDash";

export const OrderHistorys: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("Order History");
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);

  const renderContent = (): React.ReactNode => {
    if (selectedOrderId) {
      return (
        <OrderDetails
          orderId={selectedOrderId}
          onBack={() => setSelectedOrderId(null)}
        />
      );
    }

    switch (activeTab) {
      case "Order History":
        return <OrderHistory onViewDetails={setSelectedOrderId} />;
      default:
        return <OrderHistory onViewDetails={setSelectedOrderId} />;
    }
  };

  return <div>{renderContent()}</div>;
};
