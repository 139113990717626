
import React, { useState, FormEvent, ChangeEvent } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../contexts/userContext";
import LoginSvg from "../../../icons/login.svg";
import { FiEye, FiEyeOff } from "react-icons/fi";
import {toast } from 'react-toastify';

// Define the structure of the form data using an interface
interface LoginForm {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { login } = useUser();
  const [formError, setFormError] = useState<any>({});
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState<LoginForm>({
    email: "",
    password: "",
  });

  // Handle changes in input fields
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (formError[name]) {
      setFormError((prevErrors: any) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.password) newErrors.password = "Password is required";

    setFormError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); 
    if (!validateForm()) return;

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/login",
        formData
      );
      const token = localStorage.setItem("Token", response.data);
      const userId = response.data.user_id;
      localStorage.setItem("userId", userId);
      login();
      toast.success('You have successfully logged in!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      console.log("userId from login---", userId);

      navigate("/");
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        setFormError((prevErrors: any) => ({
          ...prevErrors,
          login: "Invalid Email and Password",
        }));
      }
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen p-4">
      <div className="flex flex-col md:flex-row bg-white rounded-lg overflow-hidden w-full max-w-4xl">
        <div className="p-8 w-full md:w-1/2">
          <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2 sm:mb-0">
              Login
            </h2>
            <a
              href="/signup"
              className="text-sm text-olive-600 hover:underline"
            >
              Sign up
            </a>
          </div>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-600 mb-1"
              >
                Email
              </label>
              <input
                type="text"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border-1 border-lime-900 rounded-md focus:outline-none focus:ring-1 focus:ring-lime-500"
              />
              {formError.email && (
                <p className="text-red-500 text-sm mt-1">{formError.email}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-600 mb-1"
              >
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border-1 border-lime-900 rounded-md focus:outline-none focus:ring-1 focus:ring-lime-500"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                >
                  {showPassword ? <FiEyeOff size={20} /> : <FiEye size={20} />}
                </button>
              </div>
              {formError.password && (
                <p className="text-red-500 text-sm mt-1">
                  {formError.password}
                </p>
              )}
            </div>
            <div className="text-right">
              <a
                href="/forgot-password"
                className="text-sm text-olive-600 hover:underline"
              >
                Forgot password?
              </a>
            </div>
            {formError.login && (
              <p className="text-red-500 text-sm mt-1">{formError.login}</p>
            )}
            <button
              style={{ background: "#556B2F", borderRadius: "20px" }}
              type="submit"
              className="w-full py-2 px-4 hover:bg-olive-700 text-white font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 "
            >
              Login
            </button>
          </form>
        </div>
        <div className="w-full md:w-1/2 bg-olive-100 flex items-center justify-center p-8">
          <img
            src={LoginSvg}
            alt="Signup illustration"
            className="w-full max-w-md h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
