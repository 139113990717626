import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { FiArrowLeftCircle } from "react-icons/fi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../contexts/userContext";
import left from "../../../icons/leftPage.svg";
import right from "../../../icons/right.svg";
import logout from "../../../icons/logout.svg";
import orderHistory from "../../../icons/orderhistory.svg";
import setting from "../../../icons/setting.svg";
// Types and Interfaces
interface Order {
  _id: string;
  userId: {
    firstName: string;
    lastName: string;
  };
  price: string;
  dateTime: string | null;
  createdAt: string;
  productId: string | null;
  quantity: number;
  status: boolean;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

interface LogoutDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

interface SidebarProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface OrderHistoryProps {
  onViewDetails: (orderId: string) => void;
}

interface OrderDetailsProps {
  orderId: string;
  onBack: () => void;
}

interface OverviewItem {
  label: string;
  value: string | undefined;
  actions?: React.ReactNode[];
}

// Utility Functions
const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "2-digit",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};

// Custom Modal Component
const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleModalClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center">
      <div
        className="bg-white rounded-lg p-6 max-w-md w-full m-4"
        onClick={handleModalClick}
      >
        {children}
      </div>
      <div className="fixed inset-0 z-40" onClick={onClose}></div>
    </div>
  );
};

// LogoutDialog Component
interface LogoutDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const LogoutDialog: React.FC<LogoutDialogProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { logout } = useUser();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  // Stop propagation to prevent modal from closing when clicking inside
  const handleContentClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);

      // Call context logout if available
      if (logout) {
        await logout();
      }

      // Clear all storage
      localStorage.clear();
      sessionStorage.clear();

      // Clear cookies
      document.cookie.split(";").forEach((cookie) => {
        document.cookie = cookie
          .replace(/^ +/, "")
          .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
      });

      // Call logout API endpoint
      const token = localStorage.getItem("Token");
      if (token) {
        try {
          await axios.post(
            `${process.env.REACT_APP_API_URL}/auth/logout`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } catch (error) {
          console.error("Logout API error:", error);
        }
      }

      // Ensure onClose is called before navigation
      onClose();

      // Navigate with a slight delay to ensure cleanup is complete
      setTimeout(() => {
        navigate("/", { replace: true });
      }, 100);
    } catch (error) {
      console.error("Logout failed:", error);
      alert("Failed to logout. Please try again.");
    } finally {
      setIsLoggingOut(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-6 max-w-md w-full m-4"
        onClick={handleContentClick}
      >
        <div className="relative">
          <div className="flex justify-between items-center mb-4 ">
            <h1 className="text-3xl font-bold text-gray-900">Log out</h1>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full"
              disabled={isLoggingOut}
              aria-label="Close dialog"
            >
              <FaTimes className="h-4 w-4" />
            </button>
          </div>
          <p className="">Are you sure you want to log out?</p>
          <div className="flex justify-center gap-4 mt-4 relative ml-32">
            <button
              onClick={onClose}
              className="px-6 py-2 rounded-3xl border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-200 "
              disabled={isLoggingOut}
            >
              Cancel
            </button>
            <button
              onClick={handleLogout}
              className="px-6 py-2 rounded-3xl bg-red-500 text-white hover:bg-red-600 disabled:opacity-50 focus:outline-none focus:ring-2 focus:ring-red-500"
              disabled={isLoggingOut}
            >
              {isLoggingOut ? "Logging out..." : "Log out"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Updated Sidebar Component with Fixed Event Handling
const Sidebar: React.FC<SidebarProps> = ({
  activeTab,
  setActiveTab,
  isOpen,
  setIsOpen,
}) => {
  const [showLogoutDialog, setShowLogoutDialog] = useState<boolean>(false);

  const tabs = [
    { name: "Order History", icon: orderHistory },
    { name: "Setting", icon: setting },
    { name: "Log-out", icon: logout },
  ] as const;

  const handleTabClick = (tabName: string): void => {
    if (tabName === "Log-out") {
      setShowLogoutDialog(true);
      // Don't close the sidebar immediately for logout
      return;
    }
    setActiveTab(tabName);
    setIsOpen(false);
  };

  return (
    <>
      <div
        className={`${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 fixed lg:static top-0 left-0 h-full z-10 transition-transform duration-300 ease-in-out bg-white mt-4 mr-4 py-8 w-64 rounded-lg shadow-md lg:h-48`}
      >
        {tabs.map((tab) => (
          <div
            key={tab.name}
            className={`flex items-center w-full space-x-2 p-2  mb-2 cursor-pointer px-3 ${
              activeTab === tab.name
                ? "bg-[#495e26] text-white"
                : "hover:bg-gray-100"
            }`}
            onClick={() => handleTabClick(tab.name)}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleTabClick(tab.name);
              }
            }}
          >
            <img
              src={tab.icon}
              alt={tab.name}
              className={`w-6 h-6 `}
              style={{
                filter: activeTab === tab.name ? "none" : "brightness(0.5)"
              }}
              
            />
            <span>{tab.name}</span>
          </div>
        ))}
      </div>
      {showLogoutDialog && (
        <LogoutDialog
          isOpen={showLogoutDialog}
          onClose={() => setShowLogoutDialog(false)}
        />
      )}
    </>
  );
};

// OrderDetails Component
export const OrderDetails: React.FC<OrderDetailsProps> = ({
  orderId,
  onBack,
}) => {
  const [orderDetails, setOrderDetails] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchOrderDetails = async () => {
    try {
      const token = localStorage.getItem("Token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/order/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrderDetails(response.data.data);
      setError(null);
    } catch (error) {
      console.error("Error fetching order details:", error);
      setError("Failed to load order details");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [orderId]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#4D774E]"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600 p-4">
        {error}
        <button
          onClick={fetchOrderDetails}
          className="ml-2 text-[#4D774E] hover:underline"
        >
          Retry
        </button>
      </div>
    );
  }

  if (!orderDetails) {
    return <div className="text-center">No order details found</div>;
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="">
        <button
          onClick={onBack}
          className="flex items-center hover:text-[#3d5f3e] transition-colors"
        >
          <FiArrowLeftCircle size={24} className="mr-2" />
        </button>
      </div>

      <h2 className="text-2xl font-semibold text-center mb-8 text-lime-900">
        ORDER DETAILS
      </h2>

      <div className="max-w-xl">
        <div className="space-y-4">
          <div className="flex pb-3">
            <span className="text-lime-900">ORDER ID:</span>
            <span className="font-medium text-lime-900 ml-2">
              {orderDetails._id}
            </span>
          </div>

          <div className="pb-3">
            <p className="text-lime-800 mb-1">Order Details:</p>
            <div className="space-y-2">
              <p className="text-lime-700">Amount Paid: {orderDetails.price}</p>
              <p className="text-lime-700">
                Date of Purchase: {formatDate(orderDetails.dateTime)}
              </p>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <div className="w-4 h-4 rounded-full bg-green-500"></div>
            <p className="text-lime-900">
              Tokens will be added to your username
            </p>
          </div>

          <div className="pt-4">
            <p className="text-lime-900">
              <span className="text-lime-900 underline">
                Need help with your order?{" "}
              </span>
              <a href="/contact" className="text-[#4D774E] hover:underline">
                Contact Support
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// OrderHistory Component
export const OrderHistory: React.FC<OrderHistoryProps> = ({
  onViewDetails,
}) => {
  const [orderData, setOrderData] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const ordersPerPage: number = 5;

  const userId = localStorage.getItem("userId");

  const fetchOrderDetails = async () => {
    try {
      const token = localStorage.getItem("Token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/orders/user/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("response from order details---", response.data.data);
      setOrderData(response.data.data);
      setError(null);
    } catch (error) {
      console.error("Error fetching orders:", error);
      // setError("Failed to load orders");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  const totalPages: number = Math.ceil(orderData.length / ordersPerPage);
  const indexOfLastOrder: number = currentPage * ordersPerPage;
  const indexOfFirstOrder: number = indexOfLastOrder - ordersPerPage;
  const currentOrders: Order[] = orderData.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  const paginate = (pageNumber: number): void => setCurrentPage(pageNumber);

  const pageNumbers: number[] = Array.from(
    { length: totalPages },
    (_, i) => i + 1
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#4D774E]"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600 p-4">
        {error}
        <button
          onClick={fetchOrderDetails}
          className="ml-2 text-[#4D774E] hover:underline"
        >
          Retry
        </button>
      </div>
    );
  }
  return (
    <div className="bg-white rounded-lg shadow-md overflow-x-auto">
      <h2 className="text-xl lg:text-2xl mb-4 lg:mb-6 px-5 mt-4">
        ORDER HISTORY
      </h2>

      <table className="w-full min-w-[640px]">
        <thead>
          <tr className="border-b bg-[#f2f4f5]">
            <th className="text-left px-5 py-2 text-gray-500">ORDER ID</th>
            <th className="text-left py-2 text-gray-500">USER NAME</th>
            <th className="text-left py-2 text-gray-500">DATE</th>
            <th className="text-left py-2 text-gray-500">TOTAL</th>
            <th className="text-left py-2 text-gray-500">ACTION</th>
          </tr>
        </thead>
        <tbody>
          {orderData.map((order, index) => (
            <tr key={index} className="border-b">
              <td className="py-2">{order._id}</td>
              {/*} <td className="py-2">{`${order.userId.firstName} ${order.userId.lastName}`}</td> */}
              <td className="py-2">
                {order.userId &&
                (order.userId.firstName || order.userId.lastName)
                  ? `${order.userId.firstName || ""} ${
                      order.userId.lastName || ""
                    }`.trim()
                  : ""}
              </td>

              <td className="py-2">
                {order.dateTime
                  ? new Date(order.dateTime).toLocaleDateString()
                  : "N/A"}
              </td>
              <td className="py-2">{order.price} </td>
              <td className="py-2">
                <button
                  onClick={() => onViewDetails(order._id)}
                  className="text-blue-500 hover:underline"
                >
                  View Details →
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex justify-center items-center mt-4 lg:mt-6 space-x-2">
        <button
          className="p-2 rounded-full  hover:bg-gray-300 disabled:opacity-50"
          onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
          disabled={currentPage === 1}
        >
          <img src={left} />
        </button>
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`w-8 h-8 rounded-full ${
              currentPage === number
                ? "bg-[#4D774E] text-white"
                : "bg-gray-200 hover:bg-gray-300"
            } flex items-center justify-center`}
          >
            {String(number).padStart(2, "0")}
          </button>
        ))}
        <button
          className="p-2 rounded-full  hover:bg-gray-300 disabled:opacity-50"
          onClick={() =>
            paginate(currentPage < totalPages ? currentPage + 1 : totalPages)
          }
          disabled={currentPage === totalPages}
        >
          <img src={right} />
        </button>
      </div>
    </div>
  );
};

// Setting Component
export const Setting: React.FC = () => {
  const [userData, setUserData] = useState<any>(null);
  const userId = localStorage.getItem("userId");
  const fetchUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/${userId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      console.log("Response from fetc user-->>", response.data.data);

      setUserData(response.data.data);
    } catch (error: any) {
      console.error("Error fetching user data:", error);
    }
  };
  useEffect(() => {
    fetchUser();
  }, []);
  if (!userData) {
    return <div>Loading...</div>;
  }
  console.log("userdata.kycVerified==", userData.kycVerified);

  const formattedDate = userData.createdAt
    ? new Date(userData.createdAt).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      })
    : "Not available";
  const fullName = `${userData.firstName || ""} ${userData.lastName || ""}`;
  //const kycStatus = userData.KycVerified ? "Verified" : "Not Verified";

  let kycStatus;

  if (userData.kycVerified == true) {
    kycStatus = "Verified";
  } else {
    kycStatus = "Not Verified";
  }
  const overviewData: OverviewItem[] = [
    { label: "Name", value: fullName },
    { label: "Email", value: userData.email },
    { label: "Phone no.", value: userData.phone },
    { label: "Kyc status", value: kycStatus },
    { label: "Created", value: formattedDate },
    { label: "ID", value: userData._id },
  ];
  console.log("overviewDataa----", overviewData);

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-lg font-medium mb-6">OVERVIEW</h2>
      <div className="space-y-4">
        {overviewData.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between py-2 border-b"
          >
            <span className="text-gray-600">{item.label}</span>
            <div className="flex items-center">
              {item.actions ? item.actions : <span>{item.value}</span>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Main Dashboard Component
const Dashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("Order History");
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);

  const renderContent = (): React.ReactNode => {
    if (selectedOrderId) {
      return (
        <OrderDetails
          orderId={selectedOrderId}
          onBack={() => setSelectedOrderId(null)}
        />
      );
    }

    switch (activeTab) {
      case "Order History":
        return <OrderHistory onViewDetails={setSelectedOrderId} />;
      case "Setting":
        return <Setting />;
      default:
        return <OrderHistory onViewDetails={setSelectedOrderId} />;
    }
  };

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-gray-100 p-4">
      <Sidebar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isOpen={isSidebarOpen}
        setIsOpen={setIsSidebarOpen}
      />
      <div className="flex-1 p-4 lg:p-6 mt-16 lg:mt-0">{renderContent()}</div>
    </div>
  );
};

export default Dashboard;
