import React from "react";
import Triangle from "../../../icons/triangle.svg";
import Circle from "../../../icons/circle.svg";
import Plus from "../../../icons/plus.svg";
import Egg from "../../../icons/egg.svg";
import Square from "../../../icons/square.svg";
import { useNavigate } from "react-router-dom";
import { startTransition } from "react";

interface GeometricShapeProps {
  className: string;
  iconSrc: string;
}

const GeometricShape: React.FC<GeometricShapeProps> = ({
  className,
  iconSrc,
}) => (
  <div className={`absolute ${className}`}>
    <img
      src={iconSrc}
      alt="Geometric Shape"
      className="h-full w-full object-contain"
    />
  </div>
);

const HeroSection: React.FC = () => {
  const navigate = useNavigate();

  const openShopForm = () => {
    startTransition(() => {
      navigate("/product-details");
    });
  };
  return (
    <section className="text-center py-40 relative w-full overflow-hidden">
      <GeometricShape
        className="w-12 h-12 top-20 left-1/4"
        iconSrc={Triangle}
      />
      <GeometricShape className="w-14 h-14 top-10 right-1/4" iconSrc={Square} />
      <GeometricShape
        className="w-12 h-12 bottom-20 right-1/3"
        iconSrc={Plus}
      />
      <GeometricShape className="w-8 h-8 top-1/4 right-10" iconSrc={Egg} />
      <GeometricShape
        className="w-14 h-14 bottom-10 left-10"
        iconSrc={Circle}
      />

      <h1 className="text-4xl font-bold mb-4 " style={{ color: "#000000" }}>
        Welcome to Luckshack
      </h1>
      <p className="mb-6 text-lg" style={{ color: "#000000" }}>
        Here for the Best times
      </p>
      <button
        className="bg-[#495e26] text-white px-6 py-2 rounded-md hover:bg-green-700 transition duration-300"
        onClick={openShopForm}
      >
        Buy eTokens Now
      </button>
    </section>
  );
};

export default HeroSection;
