import ReviewsSection from "./Review";
import HeroSection from "./hero";
import ProductsSection from "./products";
const Homepage = () => {
  return (
    <div className="">
      {" "}
      <HeroSection/>
      <ProductsSection/>
   {/*}   <ReviewsSection/>  */}
    </div>
  );
};

export default Homepage;
