// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import LoginSvg from "../../../icons/login.svg";
// import axios from "axios";
// import { toast } from "react-toastify";

// const Signup = () => {
//   const navigate = useNavigate();

//   const [formError, setFormError] = useState<any>({});
//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     password: "",
//     phone: "+1",
//     confirmPassword: "",
//     terms: false,
//   });

//   const handleChange = (e: any) => {
//     const { name, value } = e.target;

//     // Ensure phone number always starts with +1
//     if (name === "phone") {
//       // Prevent users from deleting the +1 prefix
//       if (!value.startsWith("+1")) {
//         setFormData((prevData) => ({
//           ...prevData,
//           phone: "+1" + value.replace(/[^0-9]/g, ""), // Keep only numbers after +1
//         }));
//       } else {
//         setFormData((prevData) => ({
//           ...prevData,
//           phone: value.replace(/[^0-9+]/g, ""), // Allow only numbers after +1
//         }));
//       }
//     } else {
//       setFormData((prevData) => ({
//         ...prevData,
//         [name]: value,
//       }));
//     }

//     // Clear specific field error on change
//     if (formError[name]) {
//       setFormError((prevErrors: any) => ({
//         ...prevErrors,
//         [name]: "",
//       }));
//     }
//   };
//   const validateForm = () => {
//     const newErrors: any = {};
//     if (!formData.firstName) newErrors.firstName = "firstName is required";
//     if (!formData.lastName) newErrors.lastName = "lastName is required";
//     if (!formData.phone) newErrors.phone = "phone is required";
//     if (!formData.email) newErrors.email = "Email is required";
//     if (!formData.password) newErrors.password = "Password is required";
//     if (!formData.confirmPassword)
//       newErrors.confirmPassword = "Confirm Password is required";
//     if (formData.password !== formData.confirmPassword)
//       newErrors.confirmPassword = "Passwords do not match";
//     if (!formData.terms) newErrors.terms = "You must agree to the Terms of Use";

//     setFormError(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };
//   const handleSubmit = async (e: any) => {
//     e.preventDefault();
//     if (!validateForm()) return;

//     try {
//       const response = await axios.post(
//         process.env.REACT_APP_API_URL + "/user/add",
//         {
//           ...formData,
//         }
//       );
//       console.log("Response from sigup form---", response.data);
//       alert("User Saved Successfully !");
//       navigate("/login");
//     } catch (error: any) {
//       console.error("Error placing order:", error);
//       if (error.response && error.response.status === 409) {
//         setFormError((prevErrors: any) => ({
//           ...prevErrors,
//           email: "User already exists", // For example, associating error with email field
//         }));
//       } else {
//         setFormError((prevErrors: any) => ({
//           ...prevErrors,
//           general: "An unexpected error occurred. Please try again.",
//         }));
//       }
//     }
//   };

//   return (
//     <div className="flex justify-center items-center min-h-screen p-4">
//       <div className="flex flex-col md:flex-row bg-white rounded-lg overflow-hidden w-full max-w-4xl">
//         <div className="p-8 w-full md:w-1/2">
//           <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
//             <h2 className="text-2xl font-semibold text-gray-800 mb-2 sm:mb-0 font-bold	">
//               Signup
//             </h2>
//             <a href="/login" className="text-sm text-olive-600 hover:underline">
//               log in instead
//             </a>
//           </div>
//           <form className="space-y-4" onSubmit={handleSubmit}>
//             <div>
//               <label
//                 htmlFor="username"
//                 className="block text-sm font-medium text-gray-600 mb-1"
//               >
//                 First name
//               </label>
//               <input
//                 type="text"
//                 id="firstName"
//                 name="firstName" // Name attribute added for state handling
//                 placeholder="Enter your firstName"
//                 value={formData.firstName} // Controlled input
//                 onChange={handleChange} // Handle input change
//                 className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-olive-500"
//               />
//                {formError.firstName && (
//                 <p className="text-red-500 text-sm mt-1">{formError.firstName}</p>
//               )}
//             </div>
//             <div>
//               <label
//                 htmlFor="username"
//                 className="block text-sm font-medium text-gray-600 mb-1"
//               >
//                 Last name
//               </label>
//               <input
//                 type="text"
//                 id="lastName"
//                 name="lastName" // Name attribute added for state handling
//                 placeholder="Enter your lastName"
//                 value={formData.lastName} // Controlled input
//                 onChange={handleChange} // Handle input change
//                 className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-olive-500"
//               />
//                {formError.lastName && (
//                 <p className="text-red-500 text-sm mt-1">{formError.lastName}</p>
//               )}
//             </div>
//             <div>
//               <label
//                 htmlFor="email"
//                 className="block text-sm font-medium text-gray-600 mb-1"
//               >
//                 Email
//               </label>
//               <input
//                 type="email"
//                 id="email"
//                 name="email" // Name attribute added for state handling
//                 placeholder="Enter your email"
//                 value={formData.email} // Controlled input
//                 onChange={handleChange} // Handle input change
//                 className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-olive-500"
//               />
//               {formError.email && (
//                 <p className="text-red-500 text-sm mt-1">{formError.email}</p>
//               )}
//             </div><div>
//               <label
//                 htmlFor="phone"
//                 className="block text-sm font-medium text-gray-600 mb-1"
//               >
//                 Phone
//               </label>
//               <input
//                 type="number"
//                 id="phone"
//                 name="phone" // Name attribute added for state handling
//                 placeholder="Enter your phone"
//                 value={formData.phone} // Controlled input
//                 onChange={handleChange} // Handle input change
//                 className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-olive-500"
//               />
//                {formError.phone && (
//                 <p className="text-red-500 text-sm mt-1">{formError.phone}</p>
//               )}
//             </div>

//             <div>
//               <label
//                 htmlFor="password"
//                 className="block text-sm font-medium text-gray-600 mb-1"
//               >
//                 Password
//               </label>
//               <input
//                 type="password"
//                 id="password"
//                 name="password" // Name attribute added for state handling
//                 placeholder="Create a password"
//                 value={formData.password} // Controlled input
//                 onChange={handleChange} // Handle input change
//                 className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-olive-500"
//               />
//               {formError.password && (
//                 <p className="text-red-500 text-sm mt-1">
//                   {formError.password}
//                 </p>
//               )}
//             </div>
//             <div>
//               <label
//                 htmlFor="confirm-password"
//                 className="block text-sm font-medium text-gray-600 mb-1"
//               >
//                 Confirm Password
//               </label>
//               <input
//                 type="password"
//                 id="confirm-password"
//                 name="confirmPassword" // Name attribute added for state handling
//                 placeholder="Confirm your password"
//                 value={formData.confirmPassword} // Controlled input
//                 onChange={handleChange} // Handle input change
//                 className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-olive-500"
//               />
//               {formError.confirmPassword && (
//                 <p className="text-red-500 text-sm mt-1">
//                   {formError.confirmPassword}
//                 </p>
//               )}
//             </div>
//             <div className="flex items-start">
//               <input
//                 type="checkbox"
//                 id="terms"
//                 name="terms" // Name attribute added for state handling
//                 checked={formData.terms} // Controlled input
//                 onChange={handleChange} // Handle input change
//                 className="mt-1 h-4 w-4 text-olive-600 focus:ring-olive-500 border-gray-300 rounded"
//               />
//               <label
//                 htmlFor="terms"
//                 className="ml-2 text-xs flex items-center text-gray-600"
//               >
//                 By signing up, I agree to the Terms of Use, Privacy Policy, and
//                 consent to receive emails and updates.
//               </label>
//               {formError.terms && (
//                 <p className="text-red-500 text-sm ml-2">{formError.terms}</p>
//               )}
//             </div>
//             {formError.general && (
//               <p className="text-red-500 text-sm mt-1">{formError.general}</p>
//             )}
//             <button
//               style={{ background: "#556B2F", borderRadius: "20px" }}
//               type="submit" // Keep as submit to trigger form submission
//               className="w-full py-2 px-4 hover:bg-olive-700 text-white font-semibold rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-olive-500"
//             >
//               Create Account
//             </button>
//           </form>
//           <div className="relative flex py-4 items-center">
//             {/* <div className="flex-grow border-t border-gray-300"></div>
//             <span className="flex-shrink mx-4 text-sm text-gray-500">Or</span>
//             <div className="flex-grow border-t border-gray-300"></div> */}
//           </div>
//           {/* <button
//             className="w-full py-2 px-4 border border-gray-300 rounded-md flex items-center justify-center hover:bg-gray-50"
//             style={{ borderRadius: "20px" }}
//           >
//             <svg className="h-5 w-5 mr-2" viewBox="0 0 24 24">
//               <path
//                 fill="#4285F4"
//                 d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
//               />
//               <path
//                 fill="#34A853"
//                 d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
//               />
//               <path
//                 fill="#FBBC05"
//                 d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
//               />
//               <path
//                 fill="#EA4335"
//                 d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
//               />
//               <path fill="none" d="M1 1h22v22H1z" />
//             </svg>
//             Continue with Google
//           </button> */}
//         </div>
//         <div className="w-full md:w-1/2 bg-olive-100 flex items-center justify-center p-8">
//           <img
//             src={LoginSvg}
//             alt="Signup illustration"
//             className="w-full max-w-md h-auto"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Signup;

//2nd draft
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginSvg from "../../../icons/login.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { FiEye, FiEyeOff } from "react-icons/fi";

const Signup = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formError, setFormError] = useState<any>({});
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    confirmPassword: "",
    terms: false,
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
 

    if (formError[name]) {
      setFormError((prevErrors: any) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!formData.firstName) newErrors.firstName = "firstName is required";
    if (!formData.lastName) newErrors.lastName = "lastName is required";
    if (!formData.phone) newErrors.phone = "phone is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.password) newErrors.password = "Password is required";
    if (!formData.confirmPassword)
      newErrors.confirmPassword = "Confirm Password is required";
    if (formData.password !== formData.confirmPassword)
      newErrors.confirmPassword = "Passwords do not match";
    if (!formData.terms) newErrors.terms = "You must agree to the Terms of Use";

    setFormError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/user/add",
        {
          ...formData,
          phone: "+1" + formData.phone, // Add the +1 prefix when submitting
        }
      );
      console.log("Response from sigup form---", response.data.data);
      toast.success('You have successfully created new user!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });    
      navigate("/login");
    } catch (error: any) {
      console.error("Error placing order:", error);
      if (error.response && error.response.status === 409) {
        setFormError((prevErrors: any) => ({
          ...prevErrors,
          email: "User already exists",
        }));
      } else {
        setFormError((prevErrors: any) => ({
          ...prevErrors,
          general: "An unexpected error occurred. Please try again.",
        }));
      }
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen p-4">
      <div className="flex flex-col md:flex-row bg-white rounded-lg overflow-hidden w-full max-w-4xl">
        <div className="p-8 w-full md:w-1/2">
          <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2 sm:mb-0 font-bold	">
              Signup
            </h2>
            <a href="/login" className="text-sm text-olive-600 hover:underline">
              log in instead
            </a>
          </div>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="username"
                className="block text-sm font-medium text-lime-900 mb-1"
              >
                First name
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="Enter your firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="w-full px-3 py-2 border-1 border-lime-900 rounded-md focus:outline-none focus:ring-1 focus:ring-lime-500"
              />
              {formError.firstName && (
                <p className="text-red-500 text-sm mt-1">
                  {formError.firstName}
                </p>
              )}
            </div>
            <div>
              <label
                htmlFor="username"
                className="block text-sm font-medium text-lime-900 mb-1"
              >
                Last name
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Enter your lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="w-full px-3 py-2 border-1 border-lime-900 rounded-md focus:outline-none focus:ring-1 focus:ring-lime-500"
              />
              {formError.lastName && (
                <p className="text-red-500 text-sm mt-1">
                  {formError.lastName}
                </p>
              )}
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-lime-900 mb-1"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border-1 border-lime-900 rounded-md focus:outline-none focus:ring-1 focus:ring-lime-500"
              />
              {formError.email && (
                <p className="text-red-500 text-sm mt-1">{formError.email}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-lime-900 mb-1"
              >
                Phone
              </label>
              <div className="flex">
                <span className="inline-flex items-center px-3 py-2 border border-r-0 border-lime-900 rounded-l-md bg-gray-100 text-gray-600">
                  +1
                </span>
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  placeholder="Enter your phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-lime-900 rounded-r-md focus:outline-none focus:ring-1 focus:ring-lime-500"
                />
              </div>
              {formError.phone && (
                <p className="text-red-500 text-sm mt-1">{formError.phone}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-lime-900 mb-1"
              >
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Create a password"
                  value={formData.password}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border-1 border-lime-900 rounded-md focus:outline-none focus:ring-1 focus:ring-lime-500"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                >
                  {showPassword ? <FiEyeOff size={20} /> : <FiEye size={20} />}
                </button>
              </div>
              {formError.password && (
                <p className="text-red-500 text-sm mt-1">
                  {formError.password}
                </p>
              )}
            </div>

            <div>
              <label
                htmlFor="confirm-password"
                className="block text-sm font-medium text-lime-900 mb-1"
              >
                Confirm Password
              </label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirm-password"
                  name="confirmPassword"
                  placeholder="Confirm your password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border-1 border-lime-900 rounded-md focus:outline-none focus:ring-1 focus:ring-lime-500"
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                >
                  {showConfirmPassword ? (
                    <FiEyeOff size={20} />
                  ) : (
                    <FiEye size={20} />
                  )}
                </button>
              </div>
              {formError.confirmPassword && (
                <p className="text-red-500 text-sm mt-1">
                  {formError.confirmPassword}
                </p>
              )}
            </div>
            <div className="flex items-start">
              <input
                type="checkbox"
                id="terms"
                name="terms"
                checked={formData.terms}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, terms: e.target.checked }))
                }
                className="mt-1 h-4 w-4 text-olive-600 focus:ring-olive-500 border-gray-300 rounded"
              />
              <label
                htmlFor="terms"
                className="ml-2 text-xs flex items-center text-gray-600"
              >
                By signing up, I agree to the Terms of Use, Privacy Policy, and
                consent to receive emails and updates.
              </label>
            </div>
            {formError.terms && (
              <p className="text-red-500 text-sm ml-2">{formError.terms}</p>
            )}
            {formError.general && (
              <p className="text-red-500 text-sm mt-1">{formError.general}</p>
            )}
            <button
              style={{ background: "#556B2F", borderRadius: "20px" }}
              type="submit"
              className="w-full py-2 px-4 hover:bg-olive-700 text-white font-semibold rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-olive-500"
            >
              Create Account
            </button>
          </form>
          <div className="relative flex py-4 items-center"></div>
        </div>
        <div className="w-full md:w-1/2 bg-olive-100 flex items-center justify-center p-8">
          <img
            src={LoginSvg}
            alt="Signup illustration"
            className="w-full max-w-md h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Signup;