import Down from "../../../icons/down.png";

import React, { useState, useEffect } from "react";
import { startTransition } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import {
  FaBars,
  FaTimes,
  FaUser,
  FaEnvelope,
  FaQuestion,
  FaHistory,
} from "react-icons/fa";
import Logo from "../../../icons/Logo.png";
import { GiBeachBag } from "react-icons/gi";
import { useUser } from "../../../contexts/userContext";
import { FaSignOutAlt } from "react-icons/fa";
import axios from "axios";
import { OrderHistory, Setting } from "../dashboard/adminDash";
import Item from "../../../layout/Navigation/Item";
const Navbar: React.FC = () => {
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const { isLoggedIn, logout } = useUser();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [fullName, setFullName] = useState<string | null>(null);


  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);

      // Call context logout if available
      if (logout) {
        await logout();
      }

      // Clear all storage
      localStorage.clear();
      sessionStorage.clear();

      // Clear cookies
      document.cookie.split(";").forEach((cookie) => {
        document.cookie = cookie
          .replace(/^ +/, "")
          .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
      });

      // Call logout API endpoint
      const token = localStorage.getItem("Token");
      if (token) {
        try {
          await axios.post(
            `${process.env.REACT_APP_API_URL}/auth/logout`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } catch (error) {
          console.error("Logout API error:", error);
        }
      }

      // Ensure onClose is called before navigation
      // Navigate with a slight delay to ensure cleanup is complete
      setTimeout(() => {
        navigate("/", { replace: true });
      }, 100);
    } catch (error) {
      console.error("Logout failed:", error);
      alert("Failed to logout. Please try again.");
    } finally {
      setIsLoggingOut(false);
    }
  };
  /*
  const checkUserId = async () => {
    const userId = await localStorage.getItem('userId');
    console.log("userId from IsLoggedIn---", userId);

    if (userId) {
      setIsLoggedIn(true); // Update the state if userId is found
    }
  };
  */
  useEffect(() => {
    console.log("isLoggedIn:", isLoggedIn);

    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    //checkUserId ();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isLoggedIn]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleNavItemClick = (itemName: string) => {
    switch (itemName) {
      case "Shop":
        startTransition(() => {
          const productId = process.env.REACT_APP_PRODUCT_ID;
          navigate(`/product-details?productId=${productId}`);
        });
        break;
      case "Contact":
        startTransition(() => {
          navigate("/contact");
        }); // Redirect to the contact route
        break;

      case "Setting":
        startTransition(() => {
          navigate("/setting");
        });
        setShowSetting(true); // Set state to render the Setting component
        break;
      case "Order History":
        startTransition(() => {
          navigate("/order-history");
        });
        setShowSetting(true); // Set state to render the Setting component
        break;
      case "FAQ":
        startTransition(() => {
          navigate("/FAQ");
        }); // Redirect to the FAQ route
        break;
      case "Terms":
        startTransition(() => {
          navigate("/terms");
        }); // Redirect to the terms route
        break;
      default:
        break;
    }
  };

  const menuItems = [
    { name: "Shop", icon: GiBeachBag },
    { name: "Contact", icon: FaEnvelope },
    ...(isLoggedIn && !isLargeScreen
      ? [
        { name: "Order History", icon: FaHistory },
        { name: "Setting", icon: FaUser },
      ]
      : []),
    { name: "FAQ", icon: FaQuestion },
  ];

  const openSignupForm = () => {
    startTransition(() => {
      navigate("/signup");
    });
  };
  const myAccount = () => {
    startTransition(() => {
      navigate("/my-account");
    });
  };
  const openLoginForm = () => {
    startTransition(() => {
      navigate("/login");
    });
  };
  const userId = localStorage.getItem('userId');
  console.log("uset id from navbar----", userId)
  useEffect(() => {
    const fetchUsername = async () => {
      if (userId) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/${userId}`);
          console.log("response from username---", response.data)
          const { firstName, lastName } = response.data.data;
          const fullName = `${firstName} ${lastName}`;
          setFullName(fullName);
        } catch (error) {
          console.error("Error fetching username:", error);
        }
      }
    };

    fetchUsername();
  }, [userId]);

  return (
    <header className="flex items-center justify-between py-4 px-6 w-full bg-white">
      <div className="flex items-center justify-center w-full lg:w-auto">
        {isLargeScreen && (
          <nav className="flex-grow">
            <ul className="flex justify-center space-x-6">
              {menuItems.map((item) => (
                <li key={item.name}>
                  <button
                    className="text-gray-900 hover:text-gray-700 transition duration-300"
                    onClick={() => handleNavItemClick(item.name)} // Call the click handler
                    disabled={item.name === "logout" && isLoggedIn}
                  >
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
      {isLargeScreen && (
        <NavLink to="/">
          <img
            style={{ height: 60 }}
            src={Logo}
            alt="Luck Shack Logo"
            className="w-auto object-contain"
          />
        </NavLink>
      )}

      {isLargeScreen ? (
        <div className="flex items-center space-x-4">
          <div className="relative inline-block">
            {/* <select
              className="appearance-none bg-white text-gray-900 px-4 py-2 pr-8 focus:border-transparent outline-none hover"
              style={{ background: "#495e26" }}
            >
              <option className="hover:text-gray-700">English</option>
              <option>Spanish</option>
              <option>French</option>
            </select> */}
            {/* <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <img alt="Down" src={Down} style={{ height: 10 }} />
            </div> */}
          </div>

          {/*}       <button className="text-gray-900 hover:text-gray-700 transition duration-300">
            <FaUser className="inline-block mr-1 w-8 h-8" />
          </button>*/}

          {/*}         <button className="text-gray-900 hover:text-gray-700 transition duration-300">
            <GiBeachBag className="inline-block mr-1 w-8 h-8" />
          </button>  
          */}
          {isLoggedIn ? (
            <>
              <button
                className="text-gray-700 hover:text-gray-900 transition duration-300"
                onClick={() => {
                  startTransition(() => {
                    navigate("/my-account");
                  });
                }}
              >
                <FaUser className="inline-block mr-1" />
                {fullName}
              </button>
            </>
          ) : (
            <>
              <button
                onClick={openLoginForm}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 border-radius-10"
                style={{ backgroundColor: "#495e26" }}
              >
                Login
              </button>
              <button
                onClick={openSignupForm}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300"
                style={{ backgroundColor: "#495e26" }}
              >
                Sign Up
              </button>
            </>
          )}
        </div>
      ) : (
        <button
          onClick={toggleMenu}
          className="text-gray-600 z-50 absolute top-4 right-6"
        >
          {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      )}

      {!isLargeScreen && (
        <div
          className={`fixed top-0 right-0 h-full w-64 bg-white z-40 transform transition-transform duration-300 ease-in-out shadow-lg ${isMenuOpen ? "translate-x-0" : "translate-x-full"
            }`}
        >
          <div className="flex flex-col h-full">
            <div className="flex justify-between items-center p-4 bg-gray-100">
              <img
                style={{ height: 60 }}
                src={Logo}
                alt="Luck Shack Logo"
                className="h-8 w-auto object-contain"
              />
            </div>
            <nav className="flex-grow overflow-y-auto">
              <ul className="space-y-2 p-4">
                {menuItems.map((item) => (
                  <li key={item.name}>
                    <h1
                      // href={`#${item.name.toLowerCase()}`}
                      className="flex items-center py-2 px-4 text-gray-700 hover:bg-gray-100 rounded transition duration-150 ease-in-out"
                      onClick={() => {
                        toggleMenu();
                        handleNavItemClick(item.name); // Call the click handler
                      }}
                    >
                      <item.icon className="mr-3" />
                      {item.name}
                    </h1>
                  </li>
                ))}
              </ul>
            </nav>

            {isLoggedIn ? (
              <>
                <button
                  className="w-[50vw] mx-auto bg-red-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 flex justify-center"
                  onClick={() => {
                    startTransition(() => {
                      handleLogout();
                      navigate("/");
                    });
                  }}
                >
                  <FaSignOutAlt className="inline-block mr-1" />
                  Logout
                </button>
                <button
                  className="text-gray-700 my-2 hover:text-gray-900 transition duration-300"
                  onClick={() => {
                    startTransition(() => {
                      navigate("/my-account");
                    });
                  }}
                >
                  <FaUser className="inline-block mr-1" />
                  My Account
                </button>
              </>
            ) : (
              <div className="p-4 border-t bg-gray-50">
                {/* <div className="flex items-center justify-between mb-4">
                <select className="border rounded px-2 py-1 w-full bg-white">
                  <option>English</option>
                  <option>Spanish</option>
                  <option>French</option>
                </select>
              </div> */}

                <div className="space-y-2">
                  <button
                    className="w-full bg-[#495e26] text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                    onClick={() => {
                      toggleMenu();
                      openLoginForm(); // Redirect to login
                    }}
                  >
                    Login
                  </button>
                  <button
                    className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300"
                    onClick={() => {
                      toggleMenu();
                      openSignupForm(); // Redirect to sign up
                    }}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Navbar;
