import React, { useState, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import { FiArrowLeftCircle } from "react-icons/fi";
import { CiCirclePlus } from "react-icons/ci";
import { MdLocalOffer } from "react-icons/md";
import axios from "axios";
import { startTransition } from "react";
import { useNavigate } from "react-router-dom";

const ProductDetails = () => {
  const [quantity, setQuantity] = useState<string>("10");
  const [couponCode, setCouponCode] = useState("");
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [productImg, setProductImg] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCouponCode = e.target.value;
    setCouponCode(newCouponCode);
  };

  const handleQuantityChange = (e: any) => {
    const inputValue = e.target.value;
    if (inputValue === "") {
      setQuantity("");
      setError(null);
      return;
    }
    if (!isNaN(Number(inputValue)) && Number(inputValue) >= 10) {
      setQuantity(inputValue);
      setError(null);
    } else if (!isNaN(inputValue)) {
      setError("Value must be 10 or greater");
      setQuantity(inputValue);
    } else {
      setQuantity(inputValue);
      setError("Please enter a valid number");
    }
  };
  const fetchProductImage = async () => {
    console.log("In fetchProductImage--", fetchProductImage);

    //const ProductId = localStorage.getItem("selectedProductId");
    const productId = process.env.REACT_APP_PRODUCT_ID;
    try {
      console.log("selectedProductId----", productId);

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/${productId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      console.log("response from fetch prodct image---", response.data);
      setProductImg(response.data.data);
    } catch (error: any) {
      console.error("Error fetching order :", error);
    }
  };
  useEffect(() => {
    fetchProductImage();
  }, []);
  const navigate = useNavigate();

  const openContinue = async() => {
    console.log("In openContinue==");
    
    const totalPrice = quantity === "" || isNaN(Number(quantity))
      ? 0
      : Number(quantity).toFixed(2);

    localStorage.setItem("totalPrice", totalPrice.toString());

    /*  startTransition(() => {
        let userId = localStorage.getItem('userId');
        console.log("userId from continue-->>>>", userId);
  
        if (userId == null || userId == undefined) {
          navigate('/login-page');
        } else {
          navigate("/payment");
  
        }
      })
  */
    let userId = localStorage.getItem("userId");
    console.log("userId from continue-->>>>", userId);

    if (!userId) {
      navigate("/login-page");
      return;
    }
    try {
      console.log("In  api ----");
      
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/${userId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const userData = response.data.data;
      console.log("userData---",userData)
      if (userData.kycVerified) {
        console.log("In kyc verifird==");
        
        navigate("/payment");
      } else {
       console.log("redirect to kyc-form page");
        navigate("/kyc-form");
      }
    } catch (error) {
      console.error("Error checking KYC status:", error);
    }
  };
  const openBakcward = () => {
    startTransition(() => {
      navigate("/");
    });
  };
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 font-sans">
      <div className="flex flex-col md:flex-row gap-8 w-full">
        <div className="md:w-1/2 flex items-start relative">
          <button
            onClick={openBakcward}
            className="absolute top-0 left-0 m-2 text-gray-900 z-10"
          >
            <FiArrowLeftCircle size={24} />
          </button>
          {productImg && productImg.imageName ? (
            <img
              //  src="https://th.bing.com/th/id/OIP.p_9mt59Vh45X1gyRQWXRaQAAAA?w=166&h=180&c=7&r=0&o=5&pid=1.7"
              src={`https://s3.wasabisys.com/productimage/${productImg.imageName}`}
              alt={productImg.name || "Product Image"}
              className="w-full h-auto max-w-md mx-auto rounded-lg"
              style={{ backgroundColor: "#f0d889" }}
            />
          ) : (
            <span className="no-image">No Image</span>
          )}
        </div>

        <div className="md:w-1/2 space-y-6">
          <h1 className="text-2xl font-bold">E Tokens</h1>
          {/* <div className="flex items-center">
            {[...Array(5)].map((_, i) => (
              <span key={i} className="text-yellow-400 text-sm">
                ★
              </span>
            ))}
            <span className="ml-2 text-xs text-gray-600">5.0 (1 Review)</span>
          </div> */}

          <div className="space-y-4 border-b border-gray-900 pb-2">
            <div className="flex items-center border rounded-md">
              <MdLocalOffer className="ml-2 text-lime-900 flex-shrink-0" />
              <input
                type="text"
                placeholder="Enter coupon code"
                className="flex-grow p-2 rounded-l-md text-sm focus:outline-none focus:lime-2 focus:ring-white focus:ring-opacity-50 color-lime-500"
                value={couponCode}
                onChange={handleCouponChange}
              />
              <button
                type="button"
                className="bg-white p-2 rounded-r-md flex-shrink-0"
              >
                <FaArrowRight size={16} className="text-lime-900" />
              </button>
            </div>

            <div>
              <label
                htmlFor="quantity"
                className="mt-1 text-xs text-gray-500 mb-2 block"
              >
                Pay What You Want
              </label>
              <div className="flex items-center border rounded-md">
                <span className="p-2 text-sm text-lime-900">$</span>
                <input
                  type="number"
                  id="quantity"
                  min="10"
                  //   step="0.01"
                  // value={quantity.toFixed(2)}
                  value={quantity}
                  onChange={handleQuantityChange}
                  className="flex-grow p-2 focus:outline-none text-sm text-lime-900"
                />
              </div>
              {error && (
                <p style={{ color: "red", marginTop: "5px" }}>{error}</p>
              )}
              <p className="mt-1 text-xs text-gray-500 mb-2">
                Pay $10.00 or more to buy this product
              </p>
              {/*}        <div className="flex items-center border rounded-md w-full max-w-xs mb-2">
                <button
                  onClick={decrementQuantity}
                  className="p-2 flex-shrink-0"
                >
                  <FaMinus size={12} className="text-lime-900" />
                </button>
                <input
                  type="number"
                  //  value={quantity.toFixed(2)}
                  value={quantity}
                  readOnly
                  className="p-2 text-center text-sm focus:outline-none focus:lime-2 focus:ring-white focus:ring-opacity-50 text-lime-900 flex-grow"
                />
                <button
                  onClick={incrementQuantity}
                  className="p-2 flex-shrink-0"
                >
                  <FaPlus size={12} className="text-lime-900" />
                </button>
              </div>   */}
              <div className="flex items-center justify-between">
                <span>Total</span>
                <span className="ml-2 text-gray-900">
                  {quantity === ""
                    ? 0
                    : isNaN(Number(quantity))
                      ? "Invalid quantity"
                      : `$${Number(quantity).toFixed(2)}`}
                </span>
                {/*}     <span className="ml-2 text-gray-900">{`$${quantity.toFixed(
                  2
                )}`}</span>    */}
              </div>
            </div>

            <button
              type="submit"
              className="w-full bg-[#495e26] text-white py-3 px-4 rounded-md hover:bg-[#3e501f] transition duration-300 text-sm"
              onClick={openContinue}
            >
              Continue
            </button>
          </div>

          <div className="space-y-4">
            <div className="border-b border-gray-900 pb-2">
              <button
                onClick={() => setIsDescriptionOpen(!isDescriptionOpen)}
                className="flex items-center w-full text-left"
              >
                <CiCirclePlus
                  size={16}
                  className="text-gray-900 mr-2 flex-shrink-0"
                />
                <span className="text-sm">Description</span>
              </button>
              {isDescriptionOpen && (
                <div className="mt-2 text-xs text-gray-600">
                  <p>This is the product description.</p>
                </div>
              )}
            </div>

          </div>
        </div>
      </div>
      <div className="mt-12">
        {/*}      <div className="mt-12 mb-12">
          <h2 className="text-xl font-bold mb-6">Reviews</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              { name: "Hellen Jummy", role: "Financial Counselor" },
              { name: "Ralph Edwards", role: "Math Teacher" },
              { name: "Hellena John", role: "Psychology Student" },
            ].map((reviewer, index) => (
              <div key={index} className="p-4 rounded-lg shadow-md">
                <p className="text-xs text-gray-700 mb-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <div className="flex items-center">
                  <img
                    src={`/api/placeholder/40/40`}
                    alt={reviewer.name}
                    className="w-8 h-8 rounded-full mr-3 flex-shrink-0"
                  />
                  <div>
                    <p className="text-sm font-semibold">{reviewer.name}</p>
                    <p className="text-xs text-gray-600">{reviewer.role}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>   */}
        {/*}       <h2 className="text-xl font-bold mb-6">Similar Products</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
          {similarProducts.map((product, index) => (
            <div
              key={index}
              className="bg-white overflow-hidden rounded-lg shadow relative"
            >
              <img
                src={product.imgUrl}
                alt={product.name}
                className="w-full h-auto"
              />
              <div className="p-4 bg-black text-white">
                <h3 className="font-semibold text-sm">{product.name}</h3>
                <p className="text-xs">{product.description}</p>
              </div>
              <button
                className="absolute inset-0 w-full h-full opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center bg-black bg-opacity-50 text-white"
                onClick={() => (window.location.href = product.link)}
              >
                Buy Now
              </button>
            </div>
          ))}
        </div>  */}
      </div>
    </div>
  );
};

export default ProductDetails;
